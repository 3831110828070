import React, { useEffect, useState } from "react";
import BoxWithLeftTitle from "../BoxWithLeftTitle/BoxWithLeftTitle";
import ActiveLoanComponent from "../ActiveLoanComponent/ActiveLoanComponent";
import { getActiveLoan } from "../../../../../../../_services/Arya2.0/Homepage";
import useIsUserLoggedIn from "../../../../CustomHooks/useIsUserLoggedIn";
import { isMobile } from "../../../../helperFunc";
import MyActiveLoanMobile from "../ActiveLoanComponentMobile/MyActiveLoanMobile";
import { customToastr } from "../../../../CustomToastr";
import FlexComponentMobile from "../FlexComponentMobile/FlexComponentMobile";
import FlexTitleComponent from "../FlexTitle/FlexTitleComponent";
import HeadLines from "../../../../CommonComponents/HeadLines";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function MyActiveLoans() {
  const [data, setData] = useState([]);
  const [userLoggedIn] = useIsUserLoggedIn();
  const history = useHistory();

  const getActiveLoanData = async () => {
    try {
      const response = await getActiveLoan();
      if (response?.status_code === 200 && response?.data?.length > 0) {
        setData(response?.data);
      } else {
        setData([]);
      }
    } catch (error) {
      customToastr({
        type: "warning",
        timeOut: 3000,
        text: error?.message,
      });
    }
  };

  useEffect(() => {
    if (userLoggedIn) {
      getActiveLoanData();
    }
  }, [userLoggedIn]);

  // Group loans by commodity name
  const groupedLoans = data.reduce((acc, loan) => {
    const commodityName = loan?.commodity_name;
    if (!acc[commodityName]) {
      acc[commodityName] = [];
    }
    acc[commodityName].push(loan);
    return acc;
  }, {});

  const renderLoans = () => {
    return isMobile ? (
      data?.map((loan, index) => (
        <MyActiveLoanMobile
          key={index}
          commodity={loan?.commodity_name}
          serialNumber={loan?.sr_no}
          disbursedAmount={loan?.disbursedAmount}
          quantityPledged={loan?.quantityPledged}
          principalPaid={loan?.principlePaidAmount}
          principalDue={loan?.principleDueAmount}
          quantityReleased={loan?.quantityReleased}
          currentQtyPledged={loan?.currentQtyPledged}
        />
      ))
    ) : (
      <div className="margin-top24 padding20 desk_card_border boxRadius8">
        {Object.keys(groupedLoans).map((commodity) => (
          <div key={commodity} className="margin-top40">
            <BoxWithLeftTitle
              title={commodity}
              className="margin-top20 padding-top16"
            >
              {groupedLoans[commodity]?.map((loan) => (
                <ActiveLoanComponent
                  key={loan?.sr_no}
                  loanData={{
                    sr: loan?.sr_no,
                    disbursed: loan?.disbursedAmount
                      ? `₹ ${loan?.disbursedAmount}`
                      : "",
                    pledged: loan?.quantityPledged || 0,
                    principle: loan?.principlePaidAmount || 0,
                    released: loan?.quantityReleased || 0,
                    due: loan?.principleDueAmount || 0,
                    qtyPledged: loan?.currentQtyPledged || 0,
                  }}
                />
              ))}
            </BoxWithLeftTitle>
          </div>
        ))}
      </div>
    );
  };

  return (
    <>
      {data && data?.length > 0 && (
        <>
          {isMobile ? (
            <FlexComponentMobile leftTitle="My Active Loans" rightNumber={12} />
          ) : (
            <div className="margin-top30">
              <FlexTitleComponent
                count={12}
                title="My Active Loans"
                rightComponent={
                  <HeadLines
                    onClick={() => {
                      history.push("/finance/active-loans");
                    }}
                    text="View details"
                    className={`${
                      isMobile
                        ? "font-size14 line-height16 colorAccent3"
                        : "font-size16 line-height19 colorAccent3"
                    } font-weight500 cursorPointer`}
                  />
                }
              />
            </div>
          )}
          {renderLoans()}
        </>
      )}
    </>
  );
}

export default MyActiveLoans;
