import { RestMethod } from "../../../_helpers/ApiConfig/RestMethod";
import FilterResponse from "../../../_helpers/FilterResponse";

import { customToastr } from "../../../_Pages/_webapp/arya2.0/CustomToastr";
const callApi = async (api, Method, data) => {
  try {
    const response = await RestMethod[Method](api, data);
    const api_response = FilterResponse(response); //If respone 200 to 299 Then return
    return api_response.data;
  } catch (error) {
    customToastr({
      text: "Something went wrong. Please try again later",
      type: "error",
    });
    console.log("error detected while fetching data from api");
    return null;
  }
};

export const getPamsBanksList = async () => {
  return callApi(`/v4/get-bank-list`,"POST", "");
};

export const addBankDetails = async (data) => {
  return callApi(`/v4/verify-bank-details`,"POST", data);
};
