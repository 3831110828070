import React, { useEffect, useReducer, useState } from "react";
import HeadLines from "../../CommonComponents/HeadLines";
import style from "./style.module.css";
import Button from "../../CommonComponents/Button";
import { bankDetail } from "../../Finance/constant";
import CustomRadioButton from "../../../../../_components/TradeContract/CustomRadioButton/CustomRadioButton";
import GridContainer from "../../Trade/Components/GridBoxDetails/GridContainer";
import { GreenThinPlusIcon, backArrow, deleteIcon } from "../../icon_assets";
import AddBankDetailPopUp from "./AddBankDetailPopUp";
import VerifyCounter from "./VerifyCounter";
import VerifySuccessFailPopUp from "./VerifySuccessFailPopUp";
import GetHelpPopUp from "./GetHelpPopUp";
import Header from "../../CommonComponents/Header";
import HeaderBackground from "../../CommonComponents/HeaderBackground";
import { isMobile } from "../../helperFunc";
import StickyFooter from "../../StickyFooter/StickyFooter";
import { useHistory } from "react-router";
import DeletePopUp from "./DeletePopUp";
import MultiLangTranslate from "../../../../../_components/Translate/MultiLangTranslate";
import { getPamsBanksList } from "../../../../../_services/Arya2.0/Pams/api";
import Loader from "../../CommonComponents/Loader/Loader";

const BankDetails = () => {
  const initialFormValue = {
    bank_name: "",
    account_number: "",
    ifsc_code: "",
    branch_name: "",
    beneficiary_name: "",
    cancel_check: "",
  };
  const history = useHistory();
  //   const [addBankDetails, dispatch] = useReducer(reducer, { age: 42 });
  const [formValue, setFormValue] = useState(initialFormValue);
  const [selectedId, setSelected] = useState(1);
  const [showAddBankDetails, setShowBankDetails] = useState(false);
  const [showCounterPopUp, setShowCounterPopup] = useState(false);
  const [showSuccessFailPopup, setShowSuccessFailPopup] = useState(false);
  const [showGetHelpPopup, setShowGetHelpPopup] = useState(false);
  const [showDeletePopUp, setDeletePopUp] = useState(false);
  const [isLoading,setIsLoading] = useState(true);
  const [apiHit,setApiHit] =useState(0)

  const  [data,setData] = useState("");


  const filterData = (dataArray)=>{
    let filteredDataArray = [];
    dataArray.forEach((ele)=>{
      filteredDataArray.push(ele.bankAccountDetails);
    })
    setData(filteredDataArray)
  }

  const getBanksList = async () =>{
    setIsLoading(true)
    let response = await getPamsBanksList();
    if(response?.data?.bankAccounts?.length>0){
      filterData(response?.data?.bankAccounts);
    }
    setIsLoading(false);
    console.log(response);
  }

  useEffect(()=>{
    getBanksList();
  },[apiHit])

console.log(data);

  const tempDetails = {
    totalCount: 4,
    bankDetail: [
      {
        name: "HDFC",
        branch: "Noida",
        acc_number: "88700047697899",
        ifsc_code: "HDFC0003660",
        beneficiary_name: "Bablu Kumar",
      },
      {
        name: "ICICI",
        branch: "Noida , Uttar Pradesh",
        acc_number: "88700047697899",
        ifsc_code: "HDFC0003660",
        beneficiary_name: "Bablu Kumar",
      },
      {
        name: "State Bank of India",
        branch: "Gurugram, Haryana",
        acc_number: "88700047697899",
        ifsc_code: "HDFC0003660",
        beneficiary_name: "Bablu Kumar rana singh",
      },
    ],
  };
  return (
    <>
    {isLoading && <Loader isLoading={isLoading} />}
      {showAddBankDetails && (
        <AddBankDetailPopUp
          onHide={setShowBankDetails}
          showModal={showAddBankDetails}
          setShowCounterPopup={setShowCounterPopup}
          setFormValue={setFormValue}
          formValue={formValue}
          setApiHit = {setApiHit}
          apiHit = {apiHit}
        />
      )}
      {showCounterPopUp && (
        <VerifyCounter
          showModal={showCounterPopUp}
          onHide={setShowCounterPopup}
          setShowSuccessFailPopup={setShowSuccessFailPopup}
        />
      )}
      {showSuccessFailPopup && (
        <VerifySuccessFailPopUp
          showModal={showSuccessFailPopup}
          onHide={setShowSuccessFailPopup}
          setShowGetHelpPopup={setShowGetHelpPopup}
          setShowBankDetails={setShowBankDetails}
          result={"failed"}
        />
      )}
      {showGetHelpPopup && (
        <GetHelpPopUp
          setFormValue={() => setFormValue(initialFormValue)}
          showModal={showGetHelpPopup}
          onHide={setShowGetHelpPopup}
        />
      )}
      {showDeletePopUp && (
        <DeletePopUp showModal={showDeletePopUp} onHide={setDeletePopUp} />
      )}
      {isMobile && (
        <HeaderBackground
          className={`borderRadiusBottom12 padding-right19 padding-left21 linear-gradient1 ${style["header-style"]}`}
        >
          <Header
            title="Bank Details"
            backArrow={backArrow}
            iconClassName={`${style["back-arrow"]} flex-center`}
            onSecondaryIconClick={() => null}
            onBackArrowClick={() => history.goBack()}
            titleClassName="font-weight600 font-size16 colorDark5 line-height19 letterSpacing015"
            className="padding-bottom16 padding-top20"
          />
        </HeaderBackground>
      )}
      <div className={isMobile ? "padding16" : ""}>
        <div className="d-flex justify-content-between align-items-center">
          <HeadLines
            className={`font-weight500 ${
              isMobile
                ? "line-height16 font-size14"
                : "line-height19 font-size16"
            } colorDark5`}
            text={
              <>
                {data?.length}{" "}
                <MultiLangTranslate title="banks found" />
              </>
            }
          />
          {!isMobile && (
            <Button
              className={`backAccent3 boxRadius6 ${style["add-btn"]} text-white font-weight500 line-height19 font-size16 `}
              title="Add Bank"
              onClick={() => setShowBankDetails(true)}
            />
          )}
          {isMobile && (
            <div
              className="d-flex gap2 align-items-center"
              onClick={() => setShowBankDetails(true)}
            >
              <img
                style={{ width: "12px", height: "12px" }}
                src={GreenThinPlusIcon}
              />
              <HeadLines
                className={`font-weight500 ${
                  isMobile
                    ? "line-height16 font-size14"
                    : "line-height19 font-size16"
                } colorAccent3`}
                text={`Add Bank Details`}
              />
            </div>
          )}
        </div>
        <HeadLines
          className={`font-weight500 ${
            isMobile ? "line-height16 font-size14" : "line-height19 font-size16"
          } colorDark5 margin-top16`}
          text="Choose default bank account"
        />
        {data &&
          data?.length > 0 &&
          data.map((item, index) => {
            return (
              <div
                className={` ${
                  isMobile
                    ? `${style["mobile-bank-card"]} margin-top12 margin-bottom16`
                    : `margin-top12 margin-bottom16 desk_card_border padding-left20 padding-top16 padding-bottom16 padding-right16`
                } boxRadius8 `}
                key={index}
              >
                <div
                  className={isMobile ? `` : "d-flex gap20 align-items-center"}
                >
                  <div
                    className={
                      isMobile
                        ? `padding-top12 padding-bottom12 padding-left16 padding-right12 d-flex justify-content-between ${style["mobile-bank-card-header"]}`
                        : "w-5"
                    }
                  >
                    <div
                      className={isMobile && `d-flex gap8 align-items-center `}
                    >
                      <CustomRadioButton
                        radioBtnProps={{
                          name: "bank_acc",
                          value: `${index + 1}`,
                          id: `bank_acc_${index + 1}`,
                          checked: selectedId === index + 1,
                          onChange: (e) => {
                            setSelected(index + 1);
                          },
                        }}
                        tdsRadio={true}
                      />
                      {isMobile && (
                        <>
                          <HeadLines
                            className={`${
                              selectedId !== index + 1
                                ? " colorDark5"
                                : "colorAccent5"
                            } font-size16 font-weight500 line-height19 ${
                              style["mt-minus-3"]
                            } `}
                            text={`${
                              selectedId !== index + 1
                                ? "Set as default account"
                                : "Default bank account"
                            }`}
                          />
                        </>
                      )}
                    </div>
                    {/* {isMobile && (
                      <>
                        {selectedId !== index + 1 && (
                          <img
                            className={`cursor-pointer ${style["delete-icon-style"]}`}
                            src={deleteIcon}
                            onClick={() => setDeletePopUp(true)}
                          />
                        )}
                      </>
                    )} */}
                  </div>

                  <GridContainer
                    titleClassName={style["grid-title"]}
                    subtitleClassName={style["grid-Subtitle"]}
                    dataDetails={[
                      { title: `${item.ifscCode}`, sub_title: "IFSC" },
                      { title: `${item.bankName}`, sub_title: "Bank" },
                      {
                        title: `${item.beneficiaryMaskedAccountNo}`,
                        sub_title: "Account Number",
                      },
                      {
                        title: `${item.beneficiaryName}`,
                        sub_title: "Beneficiary Name",
                      },
                      { title: `${item.bankCity}`, sub_title: "Branch" },
                    ]}
                    wrapper={`${style["grid-container"]}  ${
                      !isMobile && style["w-90"]
                    }`}
                  />
                  {/* {!isMobile && (
                    <div
                      className={`${style["w-5"]} d-flex justify-content-center`}
                    >
                      {selectedId !== index + 1 && (
                        <img
                          className={`cursor-pointer ${style["delete-icon-style"]}`}
                          src={deleteIcon}
                          onClick={() => setDeletePopUp(true)}
                        />
                      )}
                    </div>
                  )} */}
                </div>
              </div>
            );
          })}
      </div>

      {isMobile && <StickyFooter isActive="Profile" workEvenIfActive />}
    </>
  );
};
export default BankDetails;
