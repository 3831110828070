import React, { useState, useEffect } from "react";
import BlurModal from "../../CommonComponents/BlurModal/BlurModal";
import { isMobile } from "../../helperFunc";
import BlurModalHeading from "../../CommonComponents/BlurModal/BlurModalHeading";
import MultiLanguageTranslate from "../../../../../_components/Translate/MultiLangTranslate";
import BlurModalContent from "../../CommonComponents/BlurModal/BlurModalContent";
import InputFieldWithTitle from "../../CommonComponents/InputFieldWithTitle/InputFieldWithTitle";
import CustomReactSelect from "../../CommonComponents/CustomReactSelect/CustomReactSelect";
import {
  isNumberOnly,
  signedUrlFromDoms2,
  validationFunction,
} from "../../CommonFunctions/Function";
import s from "./style.module.css";
import { addBankDetailsRules, checkFileType } from "./constant";
import InputMobileNumber from "../../CommonComponents/InputMobileNumber";
import Title from "../../CommonComponents/InputFieldWithTitle/Title";
import Button from "../../CommonComponents/Button";
import {
  fileSizeLimit,
  fileSizeMax,
} from "../../../../../_constants/Config/AryaConstants";
import { errorInfoIcon } from "../../icon_assets";
import UploadDocumentComponent from "../../Finance/Components/UploadFile/UploadDocumentComponent";
import ErrorMessage from "../../CommonComponents/ErrorMessage/ErrorMessage";
import HeadLines from "../../CommonComponents/HeadLines";
import HeadLinesSpan from "../../CommonComponents/HeadlinesSpan";
import { addBankDetails } from "../../../../../_services/Arya2.0/Pams/api";
import Loader from "../../CommonComponents/Loader/Loader";
import { customToastr } from "../../CustomToastr";
const initialFormValue = {
  bank_name: "",
  account_number: "",
  ifsc_code: "",
  branch_name: "",
  beneficiary_name: "",
  cancel_check: "",
};
const AddBankDetailPopUp = ({
  showModal,
  onHide,
  setShowCounterPopup,
  formValue,
  setFormValue,
  apiHit,
  setApiHit
}) => {
  const [error, setError] = useState(initialFormValue);
  const [errorCallBack, setErrorCallBack] = useState("");
  const [isLoading,setIsLoading] = useState(false);
  const [bankImage,setBankImage] = useState("");
  const handleImageChange = (event) => {
    const a = event.target.files;
    setFormValue((prev) => {
      return { ...prev, ["cancel_check"]: a?.[0]};
  });
    // const fileValidate = fileValidation(a?.[0]);
    // if (fileValidate) {
    //   setFormValue((prev) => {
    //     return { ...prev, ["cancel_check"]: a?.[0] };
    //   });
    //   setError((prev) => {
    //     return { ...prev, ["cancel_check"]: "" };
    //   });
    // }
  };

  const submit = async () => {
    console.log("form Valuse", formValue);
    let flag = validationFunction(formValue, addBankDetailsRules, setError);
    console.log("flag", flag);
    let formData = formValue;
    setIsLoading(true);
    const signedUrl = await signedUrlFromDoms2(
      formValue?.cancel_check,
      setErrorCallBack
    );
    onHide(false);

    setIsLoading(false);
    if (signedUrl) {
      formData.cancel_check = signedUrl;
    } else {
      flag = 1;
    }

    if (flag === 0) {
      console.log(formValue);
      setShowCounterPopup(true);
      const response = await addBankDetails(
        formValue
      );
      if(response?.status === "success"){
        customToastr({
          text: "Bank added successfully",
          type: "success",
          timeOut: 3000,
        });
        setApiHit(apiHit+1)
      }
      setShowCounterPopup(false);

    }
  };

  const handleForm = (e) => {
    // setEditButtonEnable(true);
    const { name, value } = e.target;
    setFormValue((prev) => {
      return { ...prev, [name]: value };
    });
    const isNumberValid = value ? isNumberOnly(value) : true;
  };

  const fileValidation = (file) => {
    console.log("file?.sizefile?.size", file);
    if (file && !checkFileType.includes(file?.type)) {
      setError((prev) => ({
        ...prev,
        ["cancel_check"]: "Document must be a file of type: pdf, jpeg, png.",
      }));
      return false;
    } else if (file && file?.size > fileSizeLimit) {
      setError((prev) => ({
        ...prev,
        ["cancel_check"]: `Document may not be greater than ${fileSizeMax}MB.`,
      }));
      //2mb
      return false;
    } else {
      return true;
    }
  };
  return (
    <div className="stdFontStyle">
      {isLoading && <Loader isloading = {isLoading} />}
      <BlurModal
        show={showModal}
        onHide={() => {
          onHide();
          setFormValue(initialFormValue);
        }}
        showDesktopHeader={!isMobile}
        desktopHeading={"Add Bank"}
      >
        {isMobile && (
          <BlurModalHeading
            className={`d-flex 
                        justify-content-left
                        padding-bottom16
                        padding-top20 
                        font-weight500 font-size18 line-height21 letterSpacing0015
                        `}
            heading={<MultiLanguageTranslate title={"Add Bank"} />}
          />
        )}
        {
          <BlurModalContent>
            <div className={`stdFontStyle padding16`}>
              <UploadDocumentComponent
                className={`${isMobile ? "" : "margin-top16"} width-100`}
                customTextClassName={`${s["text-truncate-file-upload"]} text-truncate ${s["upload-placeholder-color"]}`}
                onChange={(e) => {
                  handleImageChange(e);
                }}
                value={formValue?.cancel_check?.name}
                placeholder={formValue?.cancel_check?.name || "Select File"}
                inputClassName={`width-100 boxRadius4  ${
                  s["input-border"]
                } font-weight400 ${
                  isMobile
                    ? "font-weight500 font-size14 line-height16 letterSpacing001 margin-top4 "
                    : "font-weight400 font-size16 line-height19 letterSpacing05 margin-top4"
                }`}
                inputParentClassName={s["mt4"]}
                errorInputClassName={"input-style-error"}
                errorClassName={"input-error-style"}
                title={"Upload Cancelled Cheque"}
                showError={""}
                errorIcon={errorInfoIcon}
                titleClassName={`${
                  isMobile
                    ? `font-size14 line-height16 font-weight500`
                    : "font-size16 line-height19 font-weight600 colorDark9"
                } letterSpacing05 colorDark9`}
                compulsoryStarClassName={
                  isMobile ? s["mob-astrik"] : "margin-top8"
                }
                isCompulsory={true}
                financePage={false}
                kycForm={true}
              />
              {isMobile && (
                <HeadLines
                  className={
                    "font-size10 line-height12 font-weight400 colorDark9 margin-top4"
                  }
                >
                  <HeadLinesSpan text={"*"} className={"asterisk"} />
                  Please upload a jpeg/png/pdf. Maximum file size limit is 10
                  MB.
                </HeadLines>
              )}
              {error.cancel_check && (
                <ErrorMessage
                  className={"margin-top4"}
                  text={error.cancel_check}
                />
              )}
              <InputFieldWithTitle
                titleClassName={
                  isMobile
                    ? `padding-bottom4 font-weight500 font-size14 line-height16 letterSpacing025 colorDark9`
                    : `padding-bottom4 font-weight600 font-size16 line-height19 letterSpacing05 colorDark9`
                }
                title={"Beneficiary Name"}
                isCompulsory={true}
                inputClassName={`${
                  isMobile
                    ? `font-size14 lightBorder line-height16`
                    : `${s["desk-input-style"]} font-size16 line-height19`
                } padding12 width-100 boxRadius4 placeholderColorb8bdbd colorDark1 letterSpacing001`}
                disableInputClassName={``}
                errorInputClassName={``}
                errorClassName={`${s["error-input"]} font-size10 font-weight500`}
                showError={error.beneficiary_name}
                errorIcon={errorInfoIcon}
                compulsoryStarClassName="line-height16"
                className="width-100 margin-top16"
                name={`beneficiary_name`}
                value={formValue.beneficiary_name}
                onChange={handleForm}
                type={`string`}
              />
              <InputFieldWithTitle
                titleClassName={
                  isMobile
                    ? `padding-bottom4 font-weight500 font-size14 line-height16 letterSpacing025 colorDark9`
                    : `padding-bottom4 font-weight600 font-size16 line-height19 letterSpacing05 colorDark9`
                }
                title={"Account Number"}
                isCompulsory={true}
                inputClassName={`${
                  isMobile
                    ? `font-size14 lightBorder line-height16`
                    : `${s["desk-input-style"]} font-size16 line-height19`
                } padding12 width-100 boxRadius4 placeholderColorb8bdbd colorDark1 letterSpacing001 ${
                  s["pox-password"]
                }`}
                disableInputClassName={``}
                errorInputClassName={``}
                errorClassName={`${s["error-input"]} font-size10 font-weight500`}
                showError={error.account_number}
                errorIcon={errorInfoIcon}
                compulsoryStarClassName="line-height16"
                className="width-100 margin-top16"
                name={`account_number`}
                value={formValue.account_number}
                onChange={handleForm}
                type={`number`}
              />
              <InputFieldWithTitle
                titleClassName={
                  isMobile
                    ? `padding-bottom4 font-weight500 font-size14 line-height16 letterSpacing025 colorDark9`
                    : `padding-bottom4 font-weight600 font-size16 line-height19 letterSpacing05 colorDark9`
                }
                title={"ISFC Code"}
                isCompulsory={true}
                inputClassName={`${
                  isMobile
                    ? `font-size14 lightBorder line-height16`
                    : `${s["desk-input-style"]} font-size16 line-height19`
                } padding12 width-100 boxRadius4 placeholderColorb8bdbd colorDark1 letterSpacing001`}
                disableInputClassName={``}
                errorInputClassName={``}
                errorClassName={`${s["error-input"]} font-size10 font-weight500`}
                showError={error.ifsc_code}
                errorIcon={errorInfoIcon}
                compulsoryStarClassName="line-height16"
                className="width-100 margin-top16"
                name={`ifsc_code`}
                value={formValue.ifsc_code}
                onChange={handleForm}
                type={`string`}
              />
              <InputFieldWithTitle
                titleClassName={
                  isMobile
                    ? `padding-bottom4 font-weight500 font-size14 line-height16 letterSpacing025 colorDark9`
                    : `padding-bottom4 font-weight600 font-size16 line-height19 letterSpacing05 colorDark9`
                }
                title={"Bank Name"}
                isCompulsory={true}
                inputClassName={`${
                  isMobile
                    ? `font-size14 lightBorder line-height16`
                    : `${s["desk-input-style"]} font-size16 line-height19`
                } padding12 width-100 boxRadius4 placeholderColorb8bdbd colorDark1 letterSpacing001`}
                disableInputClassName={``}
                errorInputClassName={``}
                errorClassName={`${s["error-input"]} font-size10 font-weight500`}
                showError={error.bank_name}
                errorIcon={errorInfoIcon}
                compulsoryStarClassName="line-height16"
                className="width-100 margin-top16"
                name={`bank_name`}
                value={formValue.bank_name}
                onChange={handleForm}
                type={`string`}
              />

              <InputFieldWithTitle
                titleClassName={
                  isMobile
                    ? `padding-bottom4 font-weight500 font-size14 line-height16 letterSpacing025 colorDark9`
                    : `padding-bottom4 font-weight600 font-size16 line-height19 letterSpacing05 colorDark9`
                }
                title={"Bank Branch"}
                isCompulsory={true}
                inputClassName={`${
                  isMobile
                    ? `font-size14 lightBorder line-height16 colorDark1`
                    : `${s["desk-input-style"]} font-size16 line-height19`
                } padding12 width-100 boxRadius4 placeholderColorb8bdbd colorDark1 letterSpacing001`}
                disableInputClassName={``}
                errorInputClassName={``}
                errorClassName={`${s["error-input"]} font-size10 font-weight500`}
                showError={error.branch_name}
                errorIcon={errorInfoIcon}
                compulsoryStarClassName="line-height16"
                className="width-100 margin-top16"
                name={`branch_name`}
                value={formValue.branch_name}
                onChange={handleForm}
                type={`string`}
              />
              <div
                className={`${
                  isMobile
                    ? `${s["padding-right-left18"]} padding-top8 padding-bottom8 boxRadius8`
                    : "padding16  boxRadius4"
                } backAccent9`}
              >
                <HeadLines
                  className={`font-weight400 ${
                    isMobile
                      ? "font-size14 line-height16 "
                      : "font-size16 line-height19 "
                  }colorAccent4`}
                  text="To ensure money reaches your account, Arya.ag will perform one time verification of your bank account"
                />
              </div>

              <div className="d-flex justify-content-center margin-top16">
                <Button
                  onClick={submit}
                  title={"Validate & Proceed"}
                  className={`textColorWhite backAccent3   cursor-pointer font-weight500 font-size16 line-height19 text-center  boxRadius6 ${
                    isMobile
                      ? "w-100 padding105"
                      : `${s["wid360"]} padding-top12 padding-bottom12`
                  }`}
                />
              </div>
            </div>
          </BlurModalContent>
        }
      </BlurModal>
    </div>
  );
};
export default AddBankDetailPopUp;
