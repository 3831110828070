import React, { useEffect, useState } from "react";
import HeadLines from "../CommonComponents/HeadLines";
import HeadlinesSpan from "../CommonComponents/HeadlinesSpan";
import CustomCheckBox from "../CommonComponents/CustomCheckBox/CustomCheckBox";
import { notificationFor, notificationType } from "./constant";
import styles from "./styles.module.css";
import Button from "../CommonComponents/Button";
import useUserLanguage from "../CustomHooks/useUserLanguage";
import { useDispatch } from "react-redux";
import { languageOptions as Options } from "./LanguageConstant";
import CustomRadioButton from "../../../../_components/TradeContract/CustomRadioButton/CustomRadioButton";
import { useTranslation } from "react-i18next";
import HeadLinesSpan from "../CommonComponents/HeadlinesSpan";
import HeaderBackground from "../CommonComponents/HeaderBackground";
import Header from "../CommonComponents/Header";
import { backArrow } from "../icon_assets";
import { isMobile } from "../helperFunc";
import StickyFooter from "../StickyFooter/StickyFooter";
import { useHistory } from "react-router";
import {
  getPreferenceList,
  updateLangPreference,
} from "../../../../_services/Arya2.0/Profile";
import { eventTracker } from "../GATracker3.0/eventTracker";
import { LANG_PREFERENCE_CLICKED } from "../GATracker3.0/constants";
import Loader from "../CommonComponents/Loader/Loader";
import MultiLanguageTranslate from "../../../../_components/Translate/MultiLangTranslate";
import { customToastr } from "../CustomToastr";

const NotificationPreferences = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { t, i18n } = useTranslation();
  const [selectedId, setSelected] = useState("en");
  const [whatsAppSelectedId, setWhatsAppSeletedId] = useState(1);
  const [emailSelectedId, setEmailAppSeletedId] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const userLanguage = useUserLanguage();
  const [isLanguageSelected, setIsLanguageSelected] = useState(false);
  const getData = async () => {
    setIsLoading(true);
    const res = await getPreferenceList();
    let langPresent = "";
    if (
      res?.status_code === 200 &&
      res?.data?.users?.[0]?.primaryPreferences
      ?.length
    ) {
      res?.data?.users?.[0]?.primaryPreferences
      .map((ele) => {
        if (ele?.key === "NOTIFICATION_LANG" && ele?.source === "ARYA_AG") {
          langPresent = ele?.value;
        }
      });

      if (langPresent) {
        Options?.map((ele) => {
          if (ele?.apiValue === langPresent) {
            setSelected(ele);
          }
        });
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const updatePreferences = async () => {
    eventTracker("event", LANG_PREFERENCE_CLICKED, {
      language: selectedId?.name,
    });
    setIsLoading(true);
    const res = await updateLangPreference(selectedId?.apiValue);
    if(res?.status === "success"){
      customToastr({
        text: "Language updated successfully",
        type: "success",
        timeOut: 3000,
      });
    }
    setIsLoading(false);
  };
  return (
    <>
      {isLoading && <Loader isLoading={isLoading} />}

      {/* <div>
        <HeadLines
          text="Receive notifications via"
          className="font-size16 font-weight500 letterSpacing05 line-height19"
        />
        <div className="lightBorder boxRadius4 margin-top16 margin-bottom24">
          {notificationType.map((el) => (
            <>
              <div className="d-flex justify-content-space-between padding16 ">
                <HeadlinesSpan
                  className="font-size14 line-height16 letterSpacing0125"
                  text={el.name}
                />
                <CustomCheckBox
                  id={el.id}
                  inputProps={{
                    onChange: onChange,
                    checked: notifications[el.id],
                  }}
                />
              </div>
              <hr className="m-0 p-0" />
            </>
          ))}
        </div>
      </div>

      <div>
        <HeadLines
          text="Receive notifications"
          className="font-size16 font-weight500 letterSpacing05 line-height19"
        />
        <div className="lightBorder boxRadius4 margin-top16 margin-bottom24">
          {notificationFor.map((el) => (
            <>
              <div className="d-flex justify-content-space-between padding16 ">
                <HeadlinesSpan
                  className="font-size14 line-height16 letterSpacing0125"
                  text={el.name}
                />
                <CustomCheckBox
                  id={el.id}
                  inputProps={{
                    onChange: onChange,
                    checked: notifications[el.id],
                  }}
                />
              </div>
              <hr className="m-0 p-0" />
            </>
          ))}
        </div>
      </div> */}
      {isMobile && (
        <HeaderBackground
          className={`borderRadiusBottom12 padding-right19 padding-left21 linear-gradient1 ${styles["header-style"]}`}
        >
          <Header
            title="Manage Notifications"
            backArrow={backArrow}
            iconClassName={`${styles["back-arrow"]} flex-center`}
            onSecondaryIconClick={() => null}
            onBackArrowClick={() => history.goBack()}
            titleClassName="font-weight600 font-size16 colorDark5 line-height19 letterSpacing015"
            className="padding-bottom16 padding-top20"
          />
        </HeaderBackground>
      )}
      <div className={isMobile ? "padding16" : ""}>
        <div
          className={`${
            !isMobile
              ? `${styles["manage-notify-card-style"]} padding16 `
              : "margin-top16"
          }  `}
        >
          <div className={`padding-bottom16`}>
            <HeadLines
              className={`${
                isMobile
                  ? "font-weight600 font-size14 line-height16"
                  : "font-weight500 font-size16 line-height19"
              } letterSpacing05 colorDark5`}
              text={"Receive notifications in your preferred language"}
            />
            <div className={isMobile ? "margin-top16" : "margin-top20"}>
              {Options.map((option) => {
                return (
                  <div className="d-flex gap8 margin-top12 align-items-center">
                    <CustomRadioButton
                      radioBtnProps={{
                        name: "sms",
                        value: option.name,
                        id: `sms_${option.lang_id}`,
                        checked: selectedId.lang_id === option.lang_id,
                        onChange: (e) => {
                          setSelected(option);
                        },
                      }}
                      tdsRadio={true}
                    />
                    <HeadLines
                      className={`${
                        isMobile
                          ? "font-weight400 font-size12 line-height14"
                          : "font-weight400 font-size14 line-height16"
                      } letterSpacing05 colorDark1`}
                    >
                      {option.language}{" "}
                      <HeadlinesSpan
                        className={"colorDark9"}
                        text={` (${option.title})`}
                      />
                    </HeadLines>
                  </div>
                );
              })}
            </div>
          </div>
          {/* <div className={`${styles["notify-card-hr-style"]}`}></div> */}
          {/* <div
            className={`${styles["notify-card-bottom-style"]} margin-top16 padding-bottom16`}
          >
            <HeadLines
              className={`${
                isMobile
                  ? "font-weight600 font-size14 line-height16"
                  : "font-weight500 font-size16 line-height19"
              } letterSpacing05 colorDark5`}
              text={"For Whatsapp"}
            />
            <div className={isMobile ? "margin-top16" : "margin-top20"}>
              {Options.map((option, index) => (
                <div
                  className="d-flex gap8 margin-top12 align-items-center"
                  key={index}
                >
                  <CustomRadioButton
                    radioBtnProps={{
                      name: "whats",
                      value: option.name,
                      id: `whats_${option.lang_id}`,
                      checked: whatsAppSelectedId === option.lang_id,
                      onChange: (e) => {
                        setWhatsAppSeletedId(option.lang_id);
                      },
                    }}
                    tdsRadio={true}
                  />
                  <HeadLines
                    className={`${
                      isMobile
                        ? "font-weight400 font-size12 line-height14"
                        : "font-weight400 font-size14 line-height16"
                    } letterSpacing05 colorDark1`}
                  >
                    {option.language}{" "}
                    <HeadlinesSpan
                      className={"colorDark9"}
                      text={` (${option.title})`}
                    />
                  </HeadLines>
                </div>
              ))}
            </div>
          </div>
          <div className={`${styles["notify-card-hr-style"]}`}></div>
          <div className={`${styles["notify-card-bottom-style"]} margin-top16`}>
            <HeadLines
              className={`${
                isMobile
                  ? "font-weight600 font-size14 line-height16"
                  : "font-weight500 font-size16 line-height19"
              } letterSpacing05 colorDark5`}
              text={"For Email"}
            />
            <div className={isMobile ? "margin-top16" : "margin-top20"}>
              {Options.map((option) => (
                <div className="d-flex gap8 margin-top12 align-items-center">
                  <CustomRadioButton
                    radioBtnProps={{
                      name: "email",
                      value: option.name,
                      id: `email_${option.lang_id}`,
                      checked: emailSelectedId === option.lang_id,
                      onChange: (e) => {
                        setEmailAppSeletedId(+option.lang_id);
                      },
                      disabled: option.name !== "english",
                    }}
                    tdsRadio={true}
                    disabledClassName={`${styles["custom-radio-buttom-disable"]}`}
                  />
                  <HeadLines
                    className={`${
                      isMobile
                        ? "font-weight400 font-size12 line-height14"
                        : "font-weight400 font-size14 line-height16"
                    } letterSpacing05 colorDark1 ${
                      option.name !== "english" && "screen-disable"
                    }`}
                  >
                    {option.language}{" "}
                    <HeadlinesSpan
                      className={"colorDark9"}
                      text={` (${option.title})`}
                    />
                  </HeadLines>
                </div>
              ))}
            </div>
          </div> */}
        </div>
      </div>
      <div
        className={`mx-auto margin-top24 ${
          isMobile
            ? "w-100 padding-left16 padding-right16 margin-bottom24"
            : styles["wid400"]
        }`}
      >
        <Button
          className={`cta-btn ${
            selectedId?.lang_id ? `backLightGrey` : `backAccent3`
          } ${
            isMobile
              ? "padding-top12 padding-bottom12 font-weight500 font-size14 line-height16"
              : ""
          }`}
          disabled={!selectedId?.lang_id}
          onClick={updatePreferences}
        >
          <MultiLanguageTranslate title="Save" />
        </Button>
      </div>
      {isMobile && <StickyFooter isActive="Profile" workEvenIfActive />}
    </>
  );
};

export default NotificationPreferences;
