import React from "react";
import styles from "./multiProgress.module.css";
import { isMobile } from "../../../../helperFunc";

const MultiProgressbar = ({ totalLimit, stpLimit, ltpLimit }) => {
  const getPercentage = (value) => (value / totalLimit) * 100;

  const stpPercentage = getPercentage(stpLimit);
  const ltpPercentage = getPercentage(ltpLimit);

  return (
    <div className={`${styles.container} margin-top12`}>
      <div className={styles.row}>
        <div className={styles.barContainer}>
          <div className={`${styles.fullBar} ${isMobile && styles["height5"]}`}>
            <div
              className={`${styles.limitBar} ${
                isMobile ? styles.mobstpBar : styles.deskstpBar
              }`}
              style={{ width: `${stpPercentage}%` }}
            />
            <div
              className={`${styles.limitBar} ${
                isMobile ? styles.mobltpBar : styles.deskltpBar
              }`}
              style={{
                width: `${ltpPercentage}%`,
                left: `${stpPercentage}%`,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MultiProgressbar;
