import React, { useCallback, useEffect, useState } from "react";
import BreadcrumbUI from "../DesktopCommonComponents/Breadcrumb/BreadcrumbUI";
import Header from "../CommonComponents/Header";
import HeaderBackground from "../CommonComponents/HeaderBackground";
import DesktopHeader from "../DesktopCommonComponents/DesktopHeader/DesktopHeader";
import { isMobile } from "../helperFunc";
import { backArrow, errorInfoIcon } from "../icon_assets";
import { useHistory } from "react-router-dom";
import InputFieldWithTitle from "../CommonComponents/InputFieldWithTitle/InputFieldWithTitle";
import style from "./styles.module.css";
import {
  getUserCommodityPref,
  getUserLocPref,
  updatePreferedCommodityLocation,
} from "../../../../_services/Arya2.0/Registration";
import Suggestion from "../Registration/Suggestion";
import { debounce, titleCase } from "../CommonFunctions/Function";
import Button from "../CommonComponents/Button";
import StickyFooter from "../StickyFooter/StickyFooter";
import DesktopFooter from "../DesktopCommonComponents/DesktopFooter/DesktopFooter";
import { customToastr } from "../CustomToastr";
import Loader from "../CommonComponents/Loader/Loader";
import { getUsersCommodityLocationPrefered } from "../../../../_services/Arya2.0/Registration/api";
import {
  getPreferenceList,
  updateCommodityLocationPreference,
} from "../../../../_services/Arya2.0/Profile";
const initiate = {
  preferred_buyer_locations: [],
  preferred_buyer_commodities: [],
  selectedCount: 0,
};
const CommodityLocationPreferences = (props) => {
  const { hideHeaderFooter = true } = props;
  const history = useHistory();
  const [data, setData] = useState(initiate);
  const [location, setLocation] = useState("");
  const [commodity, setCommodity] = useState("");
  const [locArray, setLocArray] = useState("");
  const [comArray, setComArray] = useState("");
  const [allCommArry, setAllComArray] = useState("");
  const [allLocArray, setAllLocArray] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [selectedCount, setSelectedCount] = useState(0);
  const getData = async () => {
    const res = await getUserLocPref("?type=preferred");
    if (res?.data?.length) {
      setLocArray(res?.data?.slice(0, 5));
      setAllLocArray(res?.data);
    }
    const res1 = await getUserCommodityPref("?type=preferred");
    if (res1?.data?.length) {
      setComArray(res1?.data?.slice(0, 5));
      setAllComArray(res1?.data);
    }
  };
  const onChange = (value) => {
    setSelectedCount((prev) => ({ ...prev, value }));
  };
  const getComlocData = async () => {
    setIsLoading(true);
    const res = await getPreferenceList();
    if (
      res?.status_code === 200 &&
      res?.data?.users?.[0]?.otherPreferences?.length
    ) {
      let selectedCommodity = [];
      let selectedLocation = [];
      res?.data?.users?.[0]?.otherPreferences.map((ele) => {
        if (ele?.key === "COMMODITY_TRADE" && ele?.source === "ARYA_AG") {
          selectedCommodity.push(ele.value);
        }
        if (ele?.key === "LOCATION_TRADE" && ele?.source === "ARYA_AG") {
          selectedLocation.push(ele.value);
        }
      });
      if (allCommArry.length > 0 && selectedCommodity) {
        const comodityFind = selectedCommodity.map((item) => {
          return allCommArry.find(
            (commodity) => titleCase(item) === titleCase(commodity?.label)
          );
        });
        comodityFind?.length > 0 &&
          setData((prev) => {
            return { ...prev, preferred_buyer_commodities: comodityFind };
          });
      }
      if (allLocArray.length > 0 && selectedLocation) {
        const locationFind = selectedLocation?.map((item) => {
          return allLocArray.find(
            (location) => titleCase(item) === titleCase(location?.label)
          );
        });
        locationFind?.length > 0 &&
          setData((prev) => {
            return { ...prev, preferred_buyer_locations: locationFind };
          });
      }
    }
    setIsLoading(false);
  };

  const handleChangeCommodity = async (value) => {
    if (value === "") {
      //  setComArray(props.commodityDataArray);
    } else {
      const res1 = await getUserCommodityPref("?q=" + value);
      if (res1?.data?.length) {
        setComArray(res1?.data?.slice(0, 5));
      } else {
        setComArray("");
      }
    }
  };
  const handleChangeLocation = async (value) => {
    if (value !== "") {
      const res = await getUserLocPref("?q=" + value);
      if (res?.data?.length) {
        setLocArray(res?.data?.slice(0, 5));
      } else {
        setLocArray("");
      }
    }
  };
  const optimizedFn = useCallback(debounce(handleChangeLocation), []);
  const optimizedFn2 = useCallback(debounce(handleChangeCommodity), []);

  useEffect(() => {
    getData();
  }, []);

  // const getData = async () => {
  //   setIsLoading(true);
  //   const res = await getPreferenceList();
  //   let langPresent = "";
  //   if (
  //     res?.status_code === 200 &&
  //     res?.data?.customerDetails?.[0]?.otherPreferences?.length
  //   ) {
  //     res?.data?.customerDetails?.[0]?.otherPreferences.map((ele) => {
  //       if (ele?.key === "NOTIFICATION_LANG" && ele?.source === "ARYA_AG") {
  //         langPresent = ele?.value;
  //       }
  //     });

  //     if (langPresent) {
  //       Options?.map((ele) => {
  //         if (ele?.apiValue === langPresent) {
  //           setSelected(ele);
  //         }
  //       });
  //     }
  //   }
  //   setIsLoading(false);
  // };

  useEffect(() => {
    if (locArray || comArray) {
      getComlocData();
    }
  }, [allCommArry]);

  const saveCommdityPreferd = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (data?.preferred_buyer_commodities || data?.preferred_buyer_locations) {
      const dataObj = {
        commodity: data?.preferred_buyer_commodities
          ? data?.preferred_buyer_commodities?.map((item) => item?.label)
          : [],
        location: data?.preferred_buyer_locations
          ? data?.preferred_buyer_locations?.map((item) => item?.label)
          : [],
      };
      const result = await updateCommodityLocationPreference(dataObj);
      if (result.status_code === 200) {
        setIsLoading(false);
        customToastr({
          type: "success",
          text: "User preferences saved successfully",
        });
      } else {
        setIsLoading(false);
      }
    }
  };
  return (
    <div>
      <Loader isLoading={isLoading} />
      {hideHeaderFooter && (
        <>
          {!isMobile && <DesktopHeader />}
          {isMobile ? (
            <HeaderBackground
              className={`borderRadiusBottom12 padding-right19 padding-left21 linear-gradient1 
        ${isMobile ? "" : "margin-bottom24"}
        `}
            >
              <Header
                title="Commodity & Location Preferences"
                backArrow={backArrow}
                onSecondaryIconClick={null}
                onBackArrowClick={() => history.goBack()}
                className="text-dark padding-bottom16 padding-top20 "
                titleClassName="font-size16 lin-height16"
              />
            </HeaderBackground>
          ) : (
            <BreadcrumbUI
              onBackArrowClick={() => history.goBack()}
              items={[
                { text: "My Profile", url: () => history.push("/my-profile") },
                {
                  text: "Settings",
                  url: "/manage-profile",
                },
                {
                  text: "Commodity & Location Preferences",
                },
              ]}
            />
          )}
        </>
      )}
      <div
        className={
          isMobile
            ? ""
            : `${
                hideHeaderFooter &&
                "d-width-2-col mx-auto margin-top24 margin-bottom40"
              }`
        }
      >
        <div
          className={
            isMobile
              ? "padding16 padding-bottom16"
              : `${hideHeaderFooter && "d-width-1-col mx-auto"}`
          }
        >
          <InputFieldWithTitle
            titleClassName={` ${
              isMobile
                ? "font-weight500 font-size14 line-height16 letterSpacing0015"
                : `font-weight500 colorDark5 font-size20 line-height23 letterSpacing001`
            }`}
            title={"Select Commodity Preference"}
            disabled={false}
            inputClassName={`${
              isMobile ? "margin-top8" : "margin-top12"
            }  margin-right16 width-100 boxRadius4 padding12 ${
              style["inputBorder"]
            } font-weight400 font-size14 line-height16 letterSpacing001 ${
              style["commodity-preference-input-style"]
            } colorDark1`}
            marginCSS="margin-bottom8"
            errorInputClassName={"input-style-error"}
            errorClassName={"input-error-style"}
            showError={""}
            errorIcon={errorInfoIcon}
            inputParentClassName="d-flex"
            value={commodity}
            onChange={(e) => {
              optimizedFn2(e.target.value);
              setCommodity(e.target.value);
            }}
          />
          {comArray?.length ? (
            <Suggestion
              dataArray={comArray}
              type={"commodity"}
              data={data}
              setData={setData}
              onChange={onChange}
              defaultMarginClass={"ml-0"}
            />
          ) : (
            ""
          )}
          <InputFieldWithTitle
            titleClassName={`margin-top16  ${
              isMobile
                ? "font-weight500 font-size14 line-height16 letterSpacing0015"
                : `font-weight500 colorDark5 font-size20 line-height23 letterSpacing001`
            }`}
            title={"Select Location Preference"}
            disabled={false}
            inputClassName={`${
              isMobile ? "margin-top8" : "margin-top12"
            }  margin-right16 width-100 boxRadius4 padding12 ${
              style["inputBorder"]
            } font-weight400 font-size14 line-height16 letterSpacing001 colorDark1 ${
              style["commodity-preference-input-style"]
            }`}
            errorInputClassName={"input-style-error"}
            errorClassName={"input-error-style"}
            showError={""}
            marginCSS="margin-bottom8"
            errorIcon={errorInfoIcon}
            inputParentClassName="d-flex"
            value={location}
            onChange={(e) => {
              optimizedFn(e.target.value);
              setLocation(e.target.value);
            }}
          />
          {locArray?.length ? (
            <Suggestion
              dataArray={locArray}
              type={"location"}
              data={data}
              setData={setData}
              onChange={onChange}
              selectedCount={selectedCount}
              defaultMarginClass={"ml-0"}
            />
          ) : (
            ""
          )}
          <div
            className={`padding16 ${
              isMobile
                ? `${style["prefered-commodity-cta"]}   w-100`
                : ` d-flex justify-content-center`
            }`}
          >
            <Button
              className={`backAccent3  text-white boxRadius6 padding-top12 padding-bottom12 ${
                isMobile
                  ? "w-100 font-size14 line-height16"
                  : "w-50 font-size16 line-height19"
              } font-weight500`}
              title="Save"
              onClick={(e) => saveCommdityPreferd(e)}
            />
          </div>
        </div>
      </div>

      {hideHeaderFooter && (
        <>{isMobile ? <StickyFooter /> : <DesktopFooter />}</>
      )}
    </div>
  );
};
export default CommodityLocationPreferences;
