import React, { Suspense, lazy, useEffect } from "react";
import toastr from "toastr";
import { Provider } from "react-redux";
import configureStore from "./_store/store";
import { createBrowserHistory } from "history";
import {
  Route,
  Switch,
  BrowserRouter as Router,
  Redirect,
  useLocation,
} from "react-router-dom";
import "react-confirm-alert/src/react-confirm-alert.css";
import { PATHS } from "./_constants/PATHS"; //this is only objects which have path and
import { USER_TYPE } from "./_constants/UserType/USER_TYPE";
import { getQueryParam } from "./_helpers/Common/helper";

import { useAppContext } from "../src/_components/ContextApis/HeaderContext";

import DefaultLoader from "./_Pages/_webapp/arya2.0/CommonComponents/Loader/DefaultLoader";
import TestSelect from "./_Pages/_webapp/arya2.0/CommonComponents/MultiSelect/TestSelect";

import BuisnessNeed from "./_Pages/_webapp/BuisnessNeed/BuisnessNeed";
import { downloadStatement } from "./_services/Arya2.0/Finance/Statement";
import AccountMenu from "./_Pages/_webapp/arya2.0/AccountSettings/AccountController.js";
import ManageUserAccountSetting from "./_Pages/_webapp/arya2.0/AccountSettings/ManageUserAccountSetting/ManageUserAccountSetting.jsx";

import {
  getCookie,
  setCrTokenCookie,
  setDeviceCookie,
} from "./_Pages/_webapp/arya2.0/helperFunc.js";
import PriceTrend from "./_Pages/_webapp/arya2.0/Storage/PriceTrend/PriceTrend.jsx";
import GetInTouchPopupForm from "./_Pages/_webapp/arya2.0/Trade/Listing/HomepageRevamp4.0/GetInTouchPopUp/GetInTouchPopupForm";
import BoxWithLeftTitle from "./_Pages/_webapp/arya2.0/Trade/Listing/HomepageRevamp4.0/BoxWithLeftTitle/BoxWithLeftTitle";
import MyActiveLoans from "./_Pages/_webapp/arya2.0/Trade/Listing/HomepageRevamp4.0/ActiveLoans/MyActiveLoans";
const ForwardTrade = lazy(() => import("./_Pages/_webapp/forwardTrade"));

const Flow4Widget = lazy(() =>
  import("./_Pages/_webapp/TradeFlow/Seller/Flow4Widget/Flow4Widget")
);

const Flow5Widget = lazy(() =>
  import("./_Pages/_webapp/TradeFlow/Seller/Flow5Widget/Flow5Widget")
);

const UploadInvoice = lazy(() =>
  import("./_Pages/_webapp/arya2.0/StpLtpTrade/UploadInvoice/UploadInvoice")
);

const LTPUploadInvoice = lazy(() =>
  import(
    "./_Pages/_webapp/arya2.0/StpLtpTrade/LTPUploadInvoice/LTPUploadInvoice"
  )
);

const ShortUrl = lazy(() =>
  import("./_Pages/_webapp/arya2.0/ShortUrl/ShortUrl")
);

const PaySTPMargin = lazy(() =>
  import("./_Pages/_webapp/arya2.0/StpLtpTrade/PaySTPMargin/PaySTPMargin")
);

const DownloadApp = lazy(() =>
  import("./_Pages/_webapp/arya2.0/DownloadApp/DownloadApp")
);
const DownloadAryaShakti = lazy(() =>
  import("./_Pages/_webapp/arya2.0/DownloadApp/DownloadAryaShakti")
);

const commonDoughnutChart = lazy(() =>
  import("./_Pages/_webapp/arya2.0/PieChart/CommonDoughnutChart")
);
const barChart = lazy(() =>
  import("./_Pages/_webapp/arya2.0/BarChart/BarChart")
);

//  auctions start

// const MandiBhavDetail = lazy(() =>
//   import(
//     "./_Pages/_webapp/arya2.0/MandiBhavPage/MandiBhavDetail/MandiBhavDetail"
//   )
// );
// const CreateAuctions = lazy(() =>
//   import("./_Pages/_webapp/arya2.0/AuctionComponents")
// );
// const MyAllBids = lazy(() =>
//   import("./_Pages/_webapp/arya2.0/AuctionComponents/AuctionListing/MyBids")
// );
// const MyAuctions = lazy(() =>
//   import("./_Pages/_webapp/arya2.0/AuctionComponents/AuctionListing/MyAuctions")
// );
// const AllAuctions = lazy(() =>
//   import(
//     "./_Pages/_webapp/arya2.0/AuctionComponents/AuctionListing/AllAuctions"
//   )
// );
// const AuctionDetails = lazy(() =>
//   import(
//     "./_Pages/_webapp/arya2.0/AuctionComponents/AuctionDetails/AuctionDetails"
//   )
// );
// const BidHistoryPage = lazy(() =>
//   import(
//     "./_Pages/_webapp/arya2.0/AuctionComponents/AuctionDetails/BidHistoryPage"
//   )
// );
// // auctions end

const AccountSettings = lazy(() =>
  import("./_Pages/_webapp/arya2.0/AccountSettings/AccountSettingLanding")
);
const ManageNotification = lazy(() =>
  import("./_Pages/_webapp/arya2.0/AccountSettings/ManageNotifications")
);
const GeneralAccountSettings = lazy(() =>
  import("./_Pages/_webapp/arya2.0/AccountSettings/AccountSettings")
);
const CommodityLocationPreferencesSettings = lazy(() =>
  import(
    "./_Pages/_webapp/arya2.0/AccountSettings/CommodityLocationPreferences"
  )
);
const LanguagePreferencesSettings = lazy(() =>
  import(
    "./_Pages/_webapp/arya2.0/AccountSettings/LangPreferences/LangPreferences.jsx"
  )
);
const ManageUsers = lazy(() =>
  import(
    "./_Pages/_webapp/arya2.0/AccountSettings/AccountTableCard/AccountTableCard.jsx"
  )
);
const NotificationPreferences = lazy(() =>
  import("./_Pages/_webapp/arya2.0/AccountSettings/NotificationPreferences.jsx")
);

const BankDetailSetting = lazy(() =>
  import("./_Pages/_webapp/arya2.0/AccountSettings/BankDetails/BankDetails.jsx")
);
const TradeDetailSetting = lazy(() =>
  import(
    "./_Pages/_webapp/arya2.0/AccountSettings/TradeDetails/TradeDetails.jsx"
  )
);
const ReleaseStockLog = lazy(() =>
  import("./_Pages/_webapp/arya2.0/ReleaseStockLog/ReleaseStockLog")
);

const OfflinePaymentLog = lazy(() =>
  import("./_Pages/_webapp/arya2.0/OfflinePaymentLog/OfflinePaymentLog")
);

const Profile2 = lazy(() => import("./_Pages/_webapp/arya2.0/Profile/Profile"));

const MyWishlist = lazy(() =>
  import("./_Pages/_webapp/arya2.0/MyWishlist/MyWishlist")
);

const CreateContract = lazy(() =>
  import("./_Pages/_webapp/arya2.0/Trade/CreateContract/CreateContract")
);

const SellerView = lazy(() =>
  import("./_Pages/_webapp/arya2.0/Trade/TradeContract/SellerView/SellerView")
);

const ContractSummary = lazy(() =>
  import("./_Pages/_webapp/arya2.0/Trade/ContractSummary/ContractSummary")
);

const NewDepositorListingPage = lazy(() =>
  import(
    "./_Pages/_webapp/arya2.0/Blockchain/Depositor/DepositorListingPage/NewDepositorListingPage"
  )
);
const NewBlockchainDetailsPage = lazy(() =>
  import(
    "./_Pages/_webapp/arya2.0/Blockchain/Depositor/NewBlockchainDetailsPage/NewBlockchainDetailsPage"
  )
);
const NewApplyLoanFormView = lazy(() =>
  import(
    "./_Pages/_webapp/arya2.0/Blockchain/Depositor/Components/NewApplyLoanFormView/NewApplyLoanFormView"
  )
);
const PledgeTokenDetailPage = lazy(() =>
  import(
    "./_Pages/_webapp/arya2.0/Blockchain/Depositor/PledgeTokenDetail/PledgeTokenDetail"
  )
);
const NewTokenDetailPage = lazy(() =>
  import(
    "./_Pages/_webapp/arya2.0/Blockchain/CommonComponents/NewTokenDetailPage/NewTokenDetailPage"
  )
);
const Listing = lazy(() =>
  import("./_Pages/_webapp/arya2.0/Trade/Listing/Listing")
);
const PostYourReq = lazy(() =>
  import("./_Pages/_webapp/arya2.0/PostYourReq/PostYourReq")
);
const TradeContract = lazy(() =>
  import("./_Pages/_webapp/arya2.0/Trade/TradeContract")
);

const PopularLead = lazy(() =>
  import("./_Pages/_webapp/arya2.0/Trade/PopularLead/PopularLead")
);
const LeadDetail = lazy(() =>
  import("./_Pages/_webapp/arya2.0/Trade/Listing/LeadDetail/LeadDetail")
);
const BlockchainHistory = lazy(() =>
  import("./_Pages/_webapp/blockchain/History/BlockchainHistory")
);

const DepositorListing = lazy(() =>
  import("./_Pages/_webapp/blockchain/depositorView/ListingPage/Listing")
);
const newArya = lazy(() => import("./_Pages/_webapp/arya2.0/Controller"));
const newArya2 = lazy(() => import("./_Pages/_webapp/arya2.0/Controller2"));
const whoweserve = lazy(() =>
  import("./_Pages/_webapp/arya2.0/WhoWeServeController")
);
const storage = lazy(() =>
  import("./_Pages/_webapp/arya2.0/Storage/StorageController")
);

const storageListing = lazy(() =>
  import(
    "./_Pages/_webapp/arya2.0/Storage/StorageInvoice/StorageInvoiceListing"
  )
);

const SellStockListing = lazy(() =>
  import("./_Pages/_webapp/arya2.0/Storage/SellStock/SellStockListing.jsx")
);

const StockLedger = lazy(() =>
  import("./_Pages/_webapp/arya2.0/Storage/StockLedger/StockLedger.jsx")
);
const transferDetail = lazy(() =>
  import("./_Pages/_webapp/arya2.0/Storage/TranferRequest/TransferDetailPage")
);

const MyFinance = lazy(() =>
  import("./_Pages/_webapp/arya2.0/Finance/FinanceController")
);

// const WalletStatement = lazy(() =>
//   import("./_Pages/_webapp/arya2.0/Finance/Wallet/WalletStatement")
// );

const DisbursementRequests = lazy(() =>
  import("./_Pages/_webapp/arya2.0/Finance/LandingPage/DisbursementRequests")
);

const LoanRequests = lazy(() =>
  import("./_Pages/_webapp/arya2.0/Finance/LandingPage/LoanRequests")
);

const ActiveLoans = lazy(() =>
  import("./_Pages/_webapp/arya2.0/Finance/LandingPage/ActiveLoans")
);

const SellStockBuyerForm = lazy(() =>
  import("./_Pages/_webapp/arya2.0/Storage/SellStock/BuyerForm")
);
const GetBuyerDetails = lazy(() =>
  import("./_Pages/_webapp/arya2.0/Storage/SellStock/GetDetailspopup")
);
const SellStockMatchedBuyers = lazy(() =>
  import("./_Pages/_webapp/arya2.0/Storage/SellStock/MatchedBuyersPage")
);
const SellStockContractSummary = lazy(() =>
  import("./_Pages/_webapp/arya2.0/Storage/SellStock/ContractSummary")
);

const DisbursementRequestListing = lazy(() =>
  import(
    "./_Pages/_webapp/arya2.0/Finance/LACPageForm/DisbursementRequest/DisbursementRequestListing.jsx"
  )
);

// const CreditDetail = lazy(() =>
//   import("./_Pages/_webapp/arya2.0/Finance/TransactionLedger/CreditDetail")
// );

// const CreditWallet = lazy(() =>
//   import("./_Pages/_webapp/arya2.0/Finance/CreditWalletFlow/CreditWallet")
// );

// const TransactionLedger = lazy(() =>
//   import("./_Pages/_webapp/arya2.0/Finance/TransactionLedger/TransactionLedger")
// );

// const PayWalletAmount = lazy(() =>
//   import("./_Pages/_webapp/arya2.0/Finance/Wallet/PayWalletAmount")
// );

const LoanStatement = lazy(() =>
  import(
    "./_Pages/_webapp/arya2.0/Finance/LandingPage/LoanStatement/LoanStatement.jsx"
  )
);

const mediaCoverage = lazy(() =>
  import("./_Pages/_webapp/arya2.0/MediaCoverage/MediaCoverage")
);
const myWarehouse = lazy(() =>
  import("./_Pages/_webapp/arya2.0/Storage/MyWareHouseController")
);

const CommodityDetails = lazy(() =>
  import(
    "./_Pages/_webapp/arya2.0/Storage/CommodityDetail/CommodityDetailsPages/CommodityDetails"
  )
);

const myCommodity = lazy(() =>
  import("./_Pages/_webapp/arya2.0/Storage/MyCommodityController")
);
const bookWarehouseDetails = lazy(() =>
  import(
    "./_Pages/_webapp/arya2.0/Storage/WarehouseRewamp/WarehouseDetails/WarehouseDetails.jsx"
  )
);
const bookWarehouseRequests = lazy(() =>
  import(
    "./_Pages/_webapp/arya2.0/Storage/WarehouseRewamp/WarehouseDetails/BookingRequest/BookingRequest.jsx"
  )
);
const mobileWarehouse = lazy(() =>
  import("./_Pages/_webapp/arya2.0/WareHouse/FindWarehouse/FindWarehouse")
);
const mobileBookWarehouse = lazy(() =>
  import("./_Pages/_webapp/arya2.0/WareHouse/BookWarehouse/BookWarehouse")
);

const release_stock = lazy(() =>
  import("./_Pages/_webapp/arya2.0/Storage/ReleaseStock/ReleaseStock")
);
const paymentComponent = lazy(() =>
  import("./_Pages/_webapp/arya2.0/PaymentComponent/PaymentComponent")
);
const MobileDisbursementDetail = lazy(() =>
  import(
    "./_Pages/_webapp/arya2.0/Finance/Disbursement/DisbursementDetail/DisbursementDetail"
  )
);

const ListDisbursementToken = lazy(() =>
  import(
    "./_Pages/_webapp/arya2.0/Finance/Disbursement/DisbursementDetail/LinkedTokens"
  )
);
const getLoanDisbursement = lazy(() =>
  import(
    "./_Pages/_webapp/arya2.0/Finance/LAC/GetLoanDisbursement/GetLoanDisbursement"
  )
);

const LACPageNewForm = lazy(() =>
  import("./_Pages/_webapp/arya2.0/Finance/LACPageForm/LACNewFormPage")
);
const MobileLoanDetail = lazy(() =>
  import(
    "./_Pages/_webapp/arya2.0/Finance/Disbursement/DisbursementHistory/DisbursementHistory"
  )
);
const DisbursementListing = lazy(() =>
  import(
    "./_Pages/_webapp/arya2.0/Finance/Disbursement/DisbursementListing/DisbursementListing"
  )
);

const ForwardTradeDetails = lazy(() =>
  import(
    "./_Pages/_webapp/arya2.0/Trade/ForwardTrade/ForwardTradeDetails/ForwardTradeDetails"
  )
);
const ArjunPage = lazy(() =>
  import("./_Pages/_webapp/arya2.0/StaticPages/Arjun/ArjunController")
);
const CareerPage = lazy(() =>
  import("./_Pages/_webapp/arya2.0/StaticPages/CareerPage/CareerPage")
);
const CareerDetailPage = lazy(() =>
  import("./_Pages/_webapp/arya2.0/StaticPages/CareerPage/CareerDetailPage")
);
const AuditsPage = lazy(() =>
  import("./_Pages/_webapp/arya2.0/StaticPages/Audits/AuditsController")
);
const ConsultingPage = lazy(() =>
  import("./_Pages/_webapp/arya2.0/StaticPages/Consulting/ConsultingController")
);
const StorageLogisticPage = lazy(() =>
  import(
    "./_Pages/_webapp/arya2.0/StaticPages/Storage-logistic/StorageLogisticController"
  )
);
const LacPage = lazy(() =>
  import("./_Pages/_webapp/arya2.0/StaticPages/LAC/LacController")
);
const LiveWarehouseVideo = lazy(() =>
  import(
    "./_Pages/_webapp/arya2.0/Storage/LiveWarehouseVideo/LiveWarehouseVideo"
  )
);
const AryaVision = lazy(() =>
  import("./_Pages/_webapp/arya2.0/StaticPages/AryaVision/AryaVisionController")
);
// const browseByCommodity = lazy(() =>
//   import("./_Pages/_webapp/arya2.0/BrowseByCommodityPage/BrowseByCommodityPage")
// );
const AryaShakti = lazy(() =>
  import(
    "./_Pages/_webapp/arya2.0/StaticPages/AryaShakti/AryaShaktiNewController"
  )
);
// const bnpl = lazy(() =>
//   import("./_Pages/_webapp/arya2.0/StaticPages/BNPL/BNPLController")
// );
const supplies_demands = lazy(() =>
  import(
    "./_Pages/_webapp/arya2.0/StaticPages/SuppliesDemand/SuppliesDemandController"
  )
);
// const EAuction = lazy(() =>
//   import("./_Pages/_webapp/arya2.0/StaticPages/E-Auction/AuctionController")
// );
const FindWarehouse = lazy(() =>
  import(
    "./_Pages/_webapp/arya2.0/StaticPages/FindWarehouse/FindWarehouseController"
  )
);
const AboutArya = lazy(() =>
  import("./_Pages/_webapp/arya2.0/StaticPages/AboutArya/AboutAryaController")
);
const BankerListing = lazy(() =>
  import("./_Pages/_webapp/blockchain/bankerView/ListingPage/Listing")
);
const BankerPublicListing = lazy(() =>
  import("./_Pages/_webapp/blockchain/banker_public_view/ListingPage/Listing")
);

const BlockchainDetailsPage = lazy(() =>
  import(
    "./_Pages/_webapp/blockchain/depositorView/BlockchainDetailsPage/BlockchainDetailsPage"
  )
);
const BlockchainCustomizeFilterListing = lazy(() =>
  import(
    "./_Pages/_webapp/blockchain/bankerView/BlockchainListing/BlockchainListing"
  )
);
const TokenDetailPage = lazy(() =>
  import(
    "./_Pages/_webapp/blockchain/commonComponents/TokenDetailPage/TokenDetailPage"
  )
);

const ApplyLoanFormView = lazy(() =>
  import(
    "./_Pages/_webapp/blockchain/depositorView/components/ApplyLoanFormView/ApplyLoanFormView"
  )
);
const MakeLoanOfferForm = lazy(() =>
  import(
    "./_Pages/_webapp/blockchain/bankerView/MakeLoanOfferForm/MakeLoanOfferForm"
  )
);

const BlockchainInfoPage = lazy(() =>
  import("./_Pages/_webapp/blockchain/InfoPage/InfoPage")
);
const BlockchainDetailInfoPage = lazy(() =>
  import("./_Pages/_webapp/blockchain/InfoPage/DetailInfoPage")
);
const TokenDetailPageBanker = lazy(() =>
  import(
    "./_Pages/_webapp/blockchain/bankerView/TokenDetailPageBanker/TokenDetailPageBanker"
  )
);

const PublicTokenDetailPageBanker = lazy(() =>
  import(
    "./_Pages/_webapp/blockchain/banker_public_view/TokenDetailPageBanker/TokenDetailPageBanker"
  )
);

const CustomizeForCommodityAndCity = lazy(() =>
  import(
    "./_Pages/_webapp/blockchain/bankerView/CustomizeForCommodityAndCity/CustomizeForCommodityAndCity"
  )
);

const CustomizeMultipleCommodityAndCity = lazy(() =>
  import(
    "./_Pages/_webapp/blockchain/bankerView/CustomizeMultipleCommodityAndCity/CustomizeMultipleCommodityAndCity"
  )
);

const MarketplaceStackDetail = lazy(() =>
  import(
    "./_Pages/_webapp/blockchain/commonComponents/MarketplaceStackDetail/MarketplaceStackDetail"
  )
);

const BlockChainPublicList = lazy(() =>
  import("./_Pages/_webapp/arya2.0/Blockchain/PublicPage/PublicPage")
);

const BlockChainPublicDetail = lazy(() =>
  import(
    "./_Pages/_webapp/arya2.0/Blockchain/PublicPage/PublicDetailPage/PublicDetailPage"
  )
);

const PrivateRoute = lazy(() => import("../src/_routes/PrivateRoute"));
const LoginMandatoryRoute = lazy(() =>
  import("../src/_routes/LoginMandatoryRoute")
);
const BankerRoute = lazy(() => import("../src/_routes/BankerRoute"));

const BDRouting = lazy(() => import("../src/_routes/BDRouting"));
const SuperAdminRoute = lazy(() => import("../src/_routes/SuperAdminRoute"));
const LoaderWithLogo = lazy(() =>
  import("../src/_components/Loader/LoaderWithLogo")
);

const MainHome = lazy(() =>
  import("../src/_Pages/_webapp/_mainHome/mainHomeRoot")
);
const LoginProduceEstimate = lazy(() =>
  import("./_Pages/_webapp/ProduceEstimate/Auth/LoginProduceEstimate")
);
const MainProductEstimate = lazy(() =>
  import("./_Pages/_webapp/ProduceEstimate/main/MainProductEstimate")
);

const LTPReleaseCommodity = lazy(() =>
  import(
    "./_Pages/_webapp/arya2.0/StpLtpTrade/LTPReleaseCommodity/LTPReleaseCommodity.jsx"
  )
);
const MobileLTPReleaseCommodity = lazy(() =>
  import(
    "./_Pages/_webapp/arya2.0/StpLtpTrade/LTPReleaseCommodity/MobileLTPReleaseCommodity.jsx"
  )
);

const LtpProformaInvoice = lazy(() =>
  import(
    "../src/_Pages/_webapp/arya2.0/StpLtpTrade/LTPReleaseCommodity/LtpProformaInvoice/DesktopProformaInvoice.jsx"
  )
);
const MobileLtpProformaInvoice = lazy(() =>
  import(
    "./_Pages/_webapp/arya2.0/StpLtpTrade/LTPReleaseCommodity/LtpProformaInvoice/MobileProformaInvoice.jsx"
  )
);

const PaymentSuccess = lazy(() =>
  import("./_components/TradeContract/Flow4Components/PaymentSuccess")
);
// const CreateMarketplaceAuction = lazy(() =>
//   import("./_Pages/_webapp/marketPlace/createAuction/CreateMarketplaceAuction")
// );

const SellerContractAccepted = lazy(() =>
  import("./_Pages/_webapp/TradeFlow/Seller/SellerContractAccepted")
);
const FinanceForm = lazy(() =>
  import("../src/_Pages/_webapp/_mainHome/FinanceForm")
);

const Warehouse = lazy(() =>
  import("../src/_Pages/_webapp/Warehouse/Warehouse")
);

const NoPageFound = lazy(() =>
  import("../src/_Pages/_webapp/arya2.0/NotFoundPage/NotFoundPage")
);
const SignUpUserByadmin = lazy(() =>
  import("../src/_Pages/_webapp/Login/SignUpUserByadmin")
);

const SignUpUserBySubadmin = lazy(() =>
  import("../src/_Pages/_webapp/Login/SignUpUserBySubadmin ")
);

const EditUserByadmin = lazy(() =>
  import("../src/_Pages/_webapp/Login/EditUserByadmin")
);
const AddCompanyUSers = lazy(() =>
  import("../src/_Pages/_webapp/Login/AddCompanyUSers")
);

// const LeadSignUp = lazy(() =>
//   import("../src/_Pages/_webapp/LeadSignup/LeadSignUp")
// );

const Dashboard = lazy(() =>
  import("../src/_Pages/_warehouse/Dashboard/Dashboard")
);
const WarehouseDetail = lazy(() =>
  import("../src/_Pages/_webapp/WarehouseDetails/MasterWHDetails")
);
const WarehouseDetailPDF = lazy(() =>
  import("../src/_Pages/_webapp/WarehouseDetails/WarehouseDetailPdf")
);
const Mywarehouses = lazy(() =>
  import("../src/_Pages/_warehouse/Mywarehouses/Mywarehouses")
);
const WarehousesName = lazy(() =>
  import("../src/_Pages/_warehouse/WarehousesName/WarehousesName")
);
const WarehouseRequest = lazy(() =>
  import("../src/_Pages/_warehouse/WarehouseRequest/WarehouseRequest")
);
const WhLog = lazy(() =>
  import("../src/_Pages/_warehouse/WarehouseRequest/WhLog")
);
const WhLogBooking = lazy(() =>
  import("../src/_Pages/_warehouse/BookedWarehouseListing/WhLogBooking")
);

const AryaproLogs = lazy(() =>
  import("../src/_Pages/_warehouse/AryaproLogs/AryaproLogs")
);

const ServiceRequestLogs = lazy(() =>
  import("../src/_Pages/_warehouse/ServiceRequestListing/ServiceRequestLogs")
);

const WarehouseRequestForm = lazy(() =>
  import("../src/_Pages/_webapp/WarehouseRequestForm/WarehouseRequestForm")
);
const EnlistThankYou = lazy(() =>
  import("./_Pages/_webapp/WarehouseRequestForm/EnlistThankYou")
);
const CompareWarehouse = lazy(() =>
  import("../src/_Pages/_webapp/CompareWarehouse/CompareWarehouse")
);
const WelcomeDashBoard = lazy(() =>
  import("../src/_Pages/_webapp/CommonDashBoard/WelcomeDashBoard")
);
const OurStory = lazy(() => import("../src/_Pages/_webapp/OurStory/OurStory"));

const ContactUsArya = lazy(() =>
  import("../src/_Pages/_webapp/ContactUsArya/ContactUsArya")
);
const EscrowFinance = lazy(() =>
  import("../src/_Pages/_webapp/EscrowFinance/EscrowFinance")
);
const BuyNowPayLater = lazy(() =>
  import("../src/_Pages/_webapp/BuyNowPayLater/BuyNowPayLater")
);

const ContactUs = lazy(() =>
  import("../src/_Pages/_webapp/ContactUs/ContactUs")
);
const Finance = lazy(() => import("../src/_Pages/_webapp/Finance/Finance"));
const WarehouseManagement = lazy(() =>
  import("../src/_Pages/_webapp/WarehouseManagement/WarehouseManagement")
);
const CommodityMarketPlace = lazy(() =>
  import("../src/_Pages/_webapp/CommodityMarketPlace/CommodityMarketPlace")
);
const CommodityMarketPlaceBuySell = lazy(() =>
  import(
    "../src/_Pages/_webapp/CommodityMarketPlace/CommodityMarketPlaceBuySell"
  )
);

const BuySellForm = lazy(() =>
  import("../src/_Pages/_webapp/CommodityMarketPlace/BuySellForm")
);
const QuoteSuccess = lazy(() =>
  import("../src/_Pages/_webapp/CommodityMarketPlace/QuoteSuccess")
);
const WarehouseSurveillance = lazy(() =>
  import("../src/_Pages/_webapp/WarehouseSurveillance/WarehouseSurveillance")
);
const Arjun = lazy(() => import("../src/_Pages/_webapp/Arjun/Arjun"));
const AryaTurns10 = lazy(() =>
  import("../src/_Pages/_webapp/AryaTurns10/AryaTurns10")
);
const MarketPlace = lazy(() =>
  import("../src/_Pages/_webapp/marketPlace/products")
);
const ProductDetails = lazy(() =>
  import("../src/_Pages/_webapp/marketPlace/productDetails")
);
const ManageProfile = lazy(() =>
  import("../src/_Pages/_webapp/marketPlace/profile")
);
const ContactSeller = lazy(() =>
  import("../src/_Pages/_webapp/marketPlace/contactSeller")
);
// const ManageProducts = lazy(() =>
//   import("../src/_Pages/_webapp/marketPlace/manageProducts")
// );
// const AddProduct = lazy(() =>
//   import("../src/_Pages/_webapp/marketPlace/addProduct")
// );
// const EditProduct = lazy(() =>
//   import("../src/_Pages/_webapp/marketPlace/editProduct")
// );
const PostSellRequirement = lazy(() =>
  import("../src/_Pages/_webapp/marketPlace/postSellRequirement")
);
const ManageRequirements = lazy(() =>
  import("../src/_Pages/_webapp/marketPlace/manageRequirements")
);
const RequirementDetail = lazy(() =>
  import("../src/_Pages/_webapp/marketPlace/requirementDetail")
);
const sellerDetails = lazy(() =>
  import("../src/_Pages/_webapp/marketPlace/sellerDetails")
);
const Insurance = lazy(() =>
  import("../src/_Pages/_webapp/Insurance/Insurance")
);
const Monitoring = lazy(() =>
  import("../src/_Pages/_webapp/Monitoring/Monitoring")
);
const QualityTesting = lazy(() =>
  import("../src/_Pages/_webapp/QualityTesting/QualityTesting")
);
const LogisticAndHandling = lazy(() =>
  import("../src/_Pages/_webapp/LogisticAndHandling/LogisticAndHandling")
);
const Fumigation = lazy(() =>
  import("../src/_Pages/_webapp/Fumigation/Fumigation")
);
const FarmerFpos = lazy(() =>
  import("../src/_Pages/_webapp/FarmerFpos/FarmerFpos")
);
const Banks = lazy(() => import("../src/_Pages/_webapp/Banks/Banks"));
const Government = lazy(() =>
  import("../src/_Pages/_webapp/Government/Government")
);
const Corporates = lazy(() =>
  import("../src/_Pages/_webapp/Corporates/Corporates")
);
const AddMandiRate = lazy(() =>
  import("../src/_Pages/_webapp/AddMandiRate/AddMandiRate")
);
const ViewMandiRate = lazy(() =>
  import("../src/_Pages/_webapp/ViewMandiRate/ViewMandiRate")
);

const PaymentPopUp = lazy(() =>
  import("./_Pages/_webapp/PaymentPopUp/PaymentPopUp")
);

const ProcessersTraders = lazy(() =>
  import("../src/_Pages/_webapp/ProcessersTraders/ProcessersTraders")
);
const WarehouseCreationForm = lazy(() =>
  import("../src/_Pages/_warehouse/CreateWarehouseForm/WarehouseCreationForm")
);
const ReachOut = lazy(() => import("../src/_Pages/_reactOut/ReachOut"));
const AryaPaysafe = lazy(() => import("../src/_Pages/_reactOut/AryaPaysafe"));
const LineForCredit = lazy(() =>
  import("../src/_Pages/_reactOut/LineForCredit")
);
const WarehouseListing = lazy(() =>
  import("../src/_Pages/_warehouse/_listing/WarehouseListing")
);
const ImageSequence = lazy(() =>
  import("../src/_Pages/_warehouse/_listing/ImageSequence")
);

const WarehouseImport = lazy(() =>
  import("../src/_Pages/_warehouse/_Import/WarehouseImport")
);
const CMS = lazy(() => import("../src/_Pages/_cms/CMS"));
const CMSEdit = lazy(() => import("../src/_Pages/_cms/CMSEdit"));

const NewsAndUpdates = lazy(() => import("../src/_Pages/_cms/NewsAndUpdates"));
const NewsAndUpdatesForm = lazy(() =>
  import("../src/_Pages/_cms/NewsAndUpdatesForm")
);

const ResearchInsights = lazy(() =>
  import("../src/_Pages/_cms/ResearchInsights")
);
const ResearchInsightsFrom = lazy(() =>
  import("../src/_Pages/_cms/ResearchInsightsFrom")
);

const AllUserListing = lazy(() =>
  import("../src/_Pages/_users/AllUserListing")
);
const ServiceRequest = lazy(() =>
  import("../src/_Pages/_serviceRequest/ServiceRequest")
);

const RolePermission = lazy(() =>
  import("./_components/_warehouse/RolePermission/RolePermission")
);
const Profile = lazy(() => import("./_Pages/_common/Profile"));
const FavWarehouse = lazy(() =>
  import("./_Pages/_warehouse/FavWarehouse/FavWarehouse")
);
const MyWarehouse = lazy(() =>
  import("./_Pages/_warehouse/MyWarehouse/MyWarehouse")
);
const CartSummary = lazy(() =>
  import("../src/_Pages/_webapp/CartSummary/CartSummary")
);
const Reports = lazy(() => import("../src/_Pages/_reports/Reports"));
const BookedWarehouse = lazy(() =>
  import("./_Pages/_warehouse/BookedWarehouse/BookedWarehouse")
);
const BookedWarehouseListing = lazy(() =>
  import("./_Pages/_warehouse/BookedWarehouseListing/BookedWarehouseListing")
);

const AdminBuyAndSellListing = lazy(() =>
  import("./_Pages/_warehouse/BuySellLisings/AdminBuyAndSellListing")
);
const SellerInfo = lazy(() =>
  import("./_Pages/_warehouse/BuySellLisings/SellerInfo")
);

const SubAdminBuyAndSellListing = lazy(() =>
  import("./_Pages/_warehouse/BuySellLisings/SubAdminBuyAndSellListing")
);
const UserBuyAndSellListing = lazy(() =>
  import("./_Pages/_warehouse/BuySellLisings/UserBuyAndSellListing")
);
const Negotiable = lazy(() =>
  import("./_Pages/_warehouse/BuySellLisings/Negotiable")
);
const NegotiableRecoed = lazy(() =>
  import("./_Pages/_warehouse/BuySellLisings/NegotiableRecoed")
);
const ThankYouNegotiation = lazy(() =>
  import("./_Pages/_warehouse/BuySellLisings/ThankYouNegotiation")
);
const CommodityParameter = lazy(() =>
  import("./_Pages/_webapp/CommodityMarketPlace/CommodityParameter")
);
const ContractMasterForm = lazy(() =>
  import("./_Pages/_webapp/CommodityMarketPlace/ContractMasterForm")
);

const SubAdmin = lazy(() => import("./_Pages/_warehouse/SubAdmin/SubAdmin"));

const AllOtherUsers = lazy(() =>
  import("./_Pages/_warehouse/AllOtherUsers/AllOtherUsers")
);

const AddSubAdmin = lazy(() =>
  import("./_Pages/_warehouse/SubAdmin/AddSubAdmin")
);
const SubAdminPermission = lazy(() =>
  import("./_Pages/_warehouse/SubAdmin/SubAdminPermission")
);
const SubAdminServices = lazy(() =>
  import("./_Pages/_warehouse/SubAdmin/SubAdminServices")
);
const ServiceRequestListing = lazy(() =>
  import("./_Pages/_warehouse/ServiceRequestListing/ServiceRequestListing")
);
const GroupsList = lazy(() =>
  import("./_Pages/_warehouse/SubAdmin/GroupsList")
);

/* OUR SERVICE*/

const Consulting = lazy(() =>
  import("./_Pages/_webapp/OurServises/Consulting")
);
const Procrument = lazy(() =>
  import("./_Pages/_webapp/OurServises/Procrument")
);
const Audits = lazy(() => import("./_Pages/_webapp/OurServises/Audits"));
const Storage = lazy(() => import("./_Pages/_webapp/OurServises/Storage"));
const Ourservices = lazy(() => import("./_Pages/_webapp/OurServises/Services"));

/* OUR SERVICE END*/

/*Mandi Price*/

const MandiPrice = lazy(() => import("./_Pages/_webapp/MandiPrice/MandiPrice"));
const MandiSell = lazy(() => import("./_Pages/_webapp/MandiPrice/Sell"));
const SellerEdit = lazy(() => import("./_Pages/_webapp/MandiPrice/SellEdit"));
const GraphMandi = lazy(() => import("./_Pages/_webapp/MandiPrice/MandiGraph"));

/*Mandi Price End*/

const AboutA2Z = lazy(() => import("../src/_Pages/_webapp/AboutA2Z/AboutA2Z"));
const EditUserGroup = lazy(() =>
  import("./_Pages/_warehouse/SubAdmin/EditUserGroup")
);

const RoleDashboard = lazy(() =>
  import("./_Pages/_webapp/RoleDashboard/RoleDashboard")
);
const EnamStorage = lazy(() =>
  import("./_Pages/_webapp/EnamStorage/EnamStorage")
);
const PaymentSuccessfull = lazy(() =>
  import("./_components/PaymentGetway/PaymentSuccessfull")
);
const PaymentSuccessfullPlan = lazy(() =>
  import("./_components/PaymentGetway/PaymentSuccessfullPlan")
);

const PaymentFailed = lazy(() =>
  import("./_components/PaymentGetway/PaymentFailed")
);
const UserEditByAdmin = lazy(() =>
  import("./_Pages/_webapp/UserEditByAdmin/UserEditByAdmin")
);
const PrivacyPolicy = lazy(() =>
  import("./_Pages/_webapp/arya2.0/StaticPages/PrivacyPolicy/PrivacyPolicy")
);
const TermsConditionsNotes = lazy(() =>
  import("./_Pages/_webapp/arya2.0/StaticPages/TermsCondition/TermsCondition")
);

const ContactUsHelpSupport = lazy(() =>
  import("../src/_Pages/_common/ContactUsHelpSupport")
);
const BookingProcess = lazy(() =>
  import("../src/_Pages/_webapp/Document/BookingProcess")
);
const TermsConditions = lazy(() =>
  import("../src/_Pages/_webapp/Document/TermsConditions")
);

const Subscription = lazy(() =>
  import("./_Pages/_warehouse/SubScription/Subscription")
);
const AllUserPlanListing = lazy(() =>
  import("./_Pages/_warehouse/SubScription/AllUserPlanListing")
);
const UserPlanHistory = lazy(() =>
  import("./_Pages/_warehouse/SubScription/UserPlanHistory")
);
const CartSummaryOfPlan = lazy(() =>
  import("./_Pages/_warehouse/SubScription/CartSummaryOfPlan")
);

const SubscriptionUserList = lazy(() =>
  import("./_Pages/_warehouse/SubScription/SubscriptionUserList")
);
const AddUpdateChecker = lazy(() =>
  import("./_Pages/_warehouse/Checker/AddUpdateChecker")
);
const AllChecker = lazy(() => import("./_Pages/_warehouse/Checker/AllChecker"));

const MediaCms = lazy(() => import("./_Pages/_warehouse/MediaCms/MediaCms"));
const AddCmsMedia = lazy(() =>
  import("./_Pages/_warehouse/MediaCms/AddCmsMedia")
);

const SettingList = lazy(() =>
  import("./_Pages/_warehouse/Settings/SettingList")
);
const AddSetting = lazy(() =>
  import("./_Pages/_warehouse/Settings/AddSetting")
);

const MakerList = lazy(() => import("./_Pages/_warehouse/Maker/MakerList"));
const AllServiceRequestList = lazy(() =>
  import("./_Pages/_warehouse/ServiceRequestListing/AllServiceRequestList")
);
const AddUpdateServiceRequest = lazy(() =>
  import("./_Pages/_warehouse/ServiceRequestListing/AddUpdateServiceRequest")
);
const AddCluster = lazy(() => import("./_Pages/_warehouse/Cluster/AddCluster"));
const AllCluster = lazy(() => import("./_Pages/_warehouse/Cluster/AllCluster"));
const ClusterUsers = lazy(() =>
  import("./_Pages/_warehouse/Cluster/ClusterUsers")
);

const RFQListing = lazy(() => import("./_Pages/_warehouse/RFQ/RFQListing"));
const addRFQ = lazy(() => import("./_Pages/_warehouse/RFQ/addRFQ"));
const RFQForUser = lazy(() => import("./_Pages/_warehouse/RFQ/RFQForUser"));
const RFQForUserListing = lazy(() =>
  import("./_Pages/_warehouse/RFQ/RFQForUserListing")
);

const WarehouseBooking = lazy(() =>
  import("./_Pages/_warehouse/WarehouseBooking/WarehouseBooking")
);
const ContractBySubadmin = lazy(() =>
  import("./_Pages/_warehouse/ContractBySubadmin/ContractBySubadmin")
);

const PwhContractBySubadmin = lazy(() =>
  import("./_Pages/_warehouse/ContractBySubadmin/PwhContractBySubadmin")
);

const PwhContractView = lazy(() =>
  import("./_Pages/_warehouse/ContractBySubadmin/PwhContractView")
);

const ContractByBDLTPSTP = lazy(() =>
  import("./_Pages/_warehouse/ContractBySubadmin/ContractByBDLTPSTP")
);
const BDLTPSTPDashboard = lazy(() =>
  import("./_Pages/_warehouse/LtpStpInvoice/BDLTPSTPDashboard")
);
const BDLTPSTPInvoice = lazy(() =>
  import("./_Pages/_warehouse/LtpStpInvoice/BDLTPSTPInvoice")
);

const BDLTPSTPInvoiceForm = lazy(() =>
  import("./_Pages/_warehouse/LtpStpInvoice/BDLTPSTPInvoiceForm")
);
const BDLTPSTPInvoiceView = lazy(() =>
  import("./_Pages/_warehouse/LtpStpInvoice/BDLTPSTPInvoiceView")
);

const PaymentLogs = lazy(() =>
  import("./_Pages/_warehouse/MyWhBooking/PaymentLogs")
);

const ContractForm = lazy(() =>
  import("./_Pages/_warehouse/ContractBySubadmin/ContractForm")
);
const ContractFormInd = lazy(() =>
  import("./_Pages/_warehouse/ContractBySubadmin/ContractFormInd")
);

const STPLTPContractView = lazy(() =>
  import("./_Pages/_warehouse/ContractBySubadmin/STPLTPContractView")
);

const MyBooking = lazy(() =>
  import("./_Pages/_warehouse/MyWhBooking/MyBooking")
);
const PaymentListing = lazy(() =>
  import("./_Pages/_warehouse/MyWhBooking/ReleaseOrder")
);

const OwnerWarehouse = lazy(() =>
  import("./_Pages/_warehouse/OwnerWarehouse/OwnerWarehouse")
);
const AddSubsription = lazy(() =>
  import("./_Pages/_warehouse/SubScription/AddSubsription")
);
const AddRequestConfiguration = lazy(() =>
  import("./_Pages/_warehouse/ConfigurationSetting/AddRequestConfiguration")
);
const ConfigurationList = lazy(() =>
  import("./_Pages/_warehouse/ConfigurationSetting/ConfigurationList")
);
const ManageDays = lazy(() =>
  import("../src/_Pages/_warehouse/HolidaysManagement/ManageDays")
);
const HolidaysListing = lazy(() =>
  import("../src/_Pages/_warehouse/HolidaysManagement/HolidaysListing")
);
const workingDaysList = lazy(() =>
  import("../src/_Pages/_warehouse/HolidaysManagement/workingDaysList")
);

const ReportsForm = lazy(() => import("./_Pages/_reports/ReportsForm"));

const LandingPage = lazy(() =>
  import("../src/_Pages/_webapp/LandingPage/LandingPage")
);
const WarehouseLandingPageListing = lazy(() =>
  import(
    "../src/_Pages/_webapp/WarehouseLandingPageListing/WarehouseLandingPageListing"
  )
);
const ContractDetails = lazy(() =>
  import("../src/_Pages/_webapp/ContractDetails")
);
const Sitemap = lazy(() => import("../src/_Pages/Sidemap/Sidemap"));
const Finances = lazy(() =>
  import("../src/_Pages/_webapp/NeedFinance/Finances")
);
const BuySellUser = lazy(() =>
  import("../src/_Pages/_webapp/NeedFinance/BuySellUser")
);
const FinanceListingForSuperAdmin = lazy(() =>
  import("./_Pages/_warehouse/FinanceLisitings/FinanceListingForSuperAdmin")
);
const UserFinanceListing = lazy(() =>
  import("./_Pages/_warehouse/FinanceLisitings/UserFinanceListing")
);
const WhLogFinance = lazy(() =>
  import("./_Pages/_warehouse/FinanceLisitings/WhLogFinance")
);
const SearchWarehouse = lazy(() =>
  import("../src/_Pages/_webapp/SearchWarehouse/FindWh")
);
const SignUp = lazy(() => import("../src/_Pages/_webapp/Login/SignUp"));
const AppSignUp = lazy(() => import("../src/_Pages/_webapp/arya2.0/AppSignUp"));

const MyLoansRequest = lazy(() =>
  import("../src/_Pages/_warehouse/MyLoansRequest/MyLoansRequest")
);
const LoanDisbursement = lazy(() =>
  import("./_Pages/_warehouse/LoanPendingDisbursement/LoanDisbursement")
);
const LoanDetail = lazy(() =>
  import("../src/_Pages/_warehouse/LoanPendingDisbursement/LoanDetail")
);
const DisbursementDetail = lazy(() =>
  import("./_Pages/_warehouse/LoanPendingDisbursement/DisbursementDetail")
);
const UserContractListing = lazy(() =>
  import("../src/_Pages/_warehouse/UsersContract/UserContractListing")
);

const MyStockListing = lazy(() =>
  import("../src/_Pages/_warehouse/MyStock/MyStockListing")
);

const ReleaseStock = lazy(() =>
  import("../src/_Pages/_warehouse/MyStock/ReleaseStock")
);
const LtpOutward = lazy(() =>
  import("./_Pages/_warehouse/TradeProcurement/LtpOutward/LtpOutward")
);
const ReleaseStockByAdmin = lazy(() =>
  import("../src/_Pages/_warehouse/MyStock/ReleaseStockByAdmin")
);
const UPIPayment = lazy(() =>
  import("../src/_Pages/_warehouse/MyStock/UPIPayment")
);

const MyLoanDataListing = lazy(() =>
  import("../src/_Pages/_warehouse/MyLoanData/MyLoanDataListing")
);
const MyReleaseOrder = lazy(() =>
  import("../src/_Pages/_warehouse/ReleaseOrder/ReleaseOrderList")
);
const CommodityListing = lazy(() =>
  import("../src/_Pages/_warehouse/CommodityListing/CommodityListing")
);
const TradePayments = lazy(() =>
  import("../src/_Pages/_warehouse/ReleaseOrder/TradePayment")
);
const STP = lazy(() => import("./_Pages/_warehouse/TradeProcurement/STP"));
const BdUserSpotPricing = lazy(() =>
  import(
    "./_Pages/_warehouse/TradeProcurement/SpotPricing/BdUserSpotPricing/BdUserSpotPricing"
  )
);

const ManageSupplierQuote = lazy(() =>
  import(
    "./_Pages/_warehouse/TradeProcurement/SpotPricing/SupplierSpotPricing/manage-supplier-quote/Manage-Price-Quote"
  )
);

const BdManageQuotations = lazy(() =>
  import(
    "./_Pages/_warehouse/TradeProcurement/SpotPricing/BdUserSpotPricing/manageQuotations/ManageQuotations"
  )
);

const SupplierSpotPricing = lazy(() =>
  import(
    "./_Pages/_warehouse/TradeProcurement/SpotPricing/SupplierSpotPricing/SupplierSpotPricing"
  )
);

const Contract = lazy(() =>
  import("./_Pages/_warehouse/TradeProcurement/Supplier/Supplier")
);
const BuyerInvoices = lazy(() =>
  import("./_Pages/_warehouse/TradeProcurement/Invoices/BuyerInvoices")
);
const SellerInvoices = lazy(() =>
  import("./_Pages/_warehouse/TradeProcurement/Invoices/SellerInvoices")
);
const InvoiceView = lazy(() =>
  import("../src/_Pages/_warehouse/ReleaseOrder/InvoiceView")
);
const InvoiceDetails = lazy(() =>
  import("../src/_Pages/_warehouse/TradeProcurement/InvoiceDetails")
);
const Contractdetails = lazy(() =>
  import("../src/_Pages/_warehouse/TradeProcurement/ContractView")
);
const SupplierInvoiceDetails = lazy(() =>
  import(
    "../src/_Pages/_warehouse/TradeProcurement/Supplier/SupplierInvoiceDetails"
  )
);
const LTPSTPDashboard = lazy(() =>
  import(
    "../src/_Pages/_warehouse/TradeProcurement/LTPSTPDashboard/LTPSTPDashboard"
  )
);
const PaymentOption = lazy(() =>
  import("../src/_Pages/_warehouse/ReleaseOrder/Paymentoption")
);
//internal retail listing
const InternalRetailListing = lazy(() =>
  import("../src/_Pages/_webapp/InternalRetailListing/InternalRetailListing")
);
const InternalBdLogin = lazy(() =>
  import("../src/_Pages/_webapp/InternalRetailListing/InternalBdLogin")
);

// who we serve

const WweProcessor = lazy(() =>
  import("./_Pages/_webapp/WhoWeServe/WhoWeServeProcessor")
);
const WhoWeServe = lazy(() =>
  import("../src/_Pages/_webapp/WhoWeServe/WhoWeServe")
);
const WhoWeServeFPO = lazy(() =>
  import("../src/_Pages/_webapp/WhoWeServe/WhoWeServeFPO")
);
const WweFinancial = lazy(() =>
  import("../src/_Pages/_webapp/WhoWeServe/Financial")
);

const WweAgriCorporation = lazy(() =>
  import("../src/_Pages/_webapp/WhoWeServe/AgriCorporation")
);
const WweInternationalBuyer = lazy(() =>
  import("../src/_Pages/_webapp/WhoWeServe/InternationalBuyer")
);
const WweDevelopmentActors = lazy(() =>
  import("../src/_Pages/_webapp/WhoWeServe/DevelopmentActors")
);

// who we serve end

//News and Update
const NewsUpdate = lazy(() =>
  import("../src/_Pages/_webapp/NewsUpdate/NewsUpdate")
);
//News and Update end

const ResearchInsight = lazy(() =>
  import("../src/_Pages/_webapp/ResearchAndInsights/ResearchAndInsights")
);

const CompanyUsers = lazy(() =>
  import("../src/_Pages/_warehouse/CompanyUsers/CompanyUsers")
);
const CompanyUsersListingSuperAdmin = lazy(() =>
  import("../src/_Pages/_warehouse/CompanyUsers/CompanyUsersListingSuperAdmin")
);

const UserOTP = lazy(() => import("../src/_Pages/_warehouse/UserOTP/UserOTP"));

const Authontication = lazy(() =>
  import("../src/_Pages/_webapp/Authontication/Authontication")
);
const BuyerEnlistListing = lazy(() =>
  import("../src/_Pages/_warehouse/BuyerEnlistListing/BuyerEnlistListing")
);
const BuyerEnlistDetails = lazy(() =>
  import("../src/_Pages/_warehouse/BuyerEnlistListing/BuyerEnlistDetails")
);
const BuyerLeadsListing = lazy(() =>
  import("../src/_Pages/_warehouse/BuyerEnlistListing/BuyerLeadsListing")
);

const MarketOpenUrl = lazy(() =>
  import("../src/_Pages/_warehouse/BuyerEnlistListing/MarketOpenUrl")
);

const ManageDoc = lazy(() =>
  import("../src/_Pages/_warehouse/ManageDoc/ManageDoc")
);
const SearchLeads = lazy(() =>
  import("../src/_Pages/_webapp/SearchLeads/SearchLeads")
);
const ThankYouPage = lazy(() =>
  import("../src/_Pages/_webapp/ThankYouPage/ThankYouPage")
);
const SearchBuyLead = lazy(() =>
  import("../src/_Pages/_webapp/SearchLeads/SearchBuyLead")
);
const Logout = lazy(() => import("../src/_Pages/_webapp/Login/Logout"));
const BuyInlistForm = lazy(() =>
  import("../src/_Pages/_webapp/BuyInlistForm/BuyInlistForm")
);
const BuyLeadDetails = lazy(() =>
  import("../src/_Pages/_webapp/BuyInlistForm/BuyLeadDetails")
);

const ConfirmPayment = lazy(() =>
  import("../src/_Pages/_warehouse/BuyerEnlistListing/ConfirmPayment")
);
const RoConfirmPayment = lazy(() =>
  import("../src/_Pages/_warehouse/BuyerEnlistListing/RoConfirmPayment")
);
const RentPaymentConfirmation = lazy(() =>
  import("../src/_Pages/_warehouse/BuyerEnlistListing/RentPaymentConfirmation")
);

const CreditWalletConfirmPayment = lazy(() =>
  import(
    "../src/_Pages/_warehouse/BuyerEnlistListing/CreditWalletConfirmPayment"
  )
);
// const AuctionConfirmPayment = lazy(() =>
//   import("../src/_Pages/_warehouse/BuyerEnlistListing/AuctionConfirmPayment")
// );
const StpConfirmPayment = lazy(() =>
  import("../src/_Pages/_warehouse/BuyerEnlistListing/StpConfirmPayment")
);
// const AuctionCreation = lazy(() =>
//   import("../src/_Pages/_webapp/Auction/CreateAuction/CreateAuction")
// );
// const AuctionsList = lazy(() =>
//   import("./_Pages/_webapp/Auction/AuctionList/AuctionList")
// );
// const AuctionDetail = lazy(() =>
//   import("./_Pages/_webapp/Auction/AuctionDetail/AuctionDetail")
// );
// const MyTrade = lazy(() => import("./_Pages/_webapp/Auction/MyTrade/MyTrade"));
// const MyBids = lazy(() => import("./_Pages/_webapp/Auction/MyTrade/MyBids"));

const CreateLeadsByAdmin = lazy(() =>
  import("../src/_Pages/_warehouse/BuyerEnlistListing/CreateLeadsByAdmin")
);

//trade contracts
const TradeContracts = lazy(() =>
  import("../src/_Pages/_webapp/TradeContracts/index")
);
const SelectSupply = lazy(() =>
  import("../src/_Pages/_webapp/TradeContracts/SelectSupply")
);
const TradeContractsListing = lazy(() =>
  import("../src/_Pages/_webapp/TradeFlow/TradeContractsListing")
);
const ViewContract = lazy(() =>
  import("../src/_Pages/_webapp/TradeFlow/Buyer/ViewContract")
);

const ViewContractForBroker = lazy(() =>
  import("./_Pages/_webapp/TradeFlow/Broker/ViewContractForBroker")
);

const WrappedComponent = lazy(() =>
  import("./_components/TradeContract/WrappedComponent/WrappedComponent")
);

// const BrokerJourney = lazy(() =>
//   import("./_Pages/_webapp/TradeFlow/Broker/BrokerJourney")
// );
//trade payments
const ViewBank = lazy(() =>
  import("../src/_Pages/_webapp/AdminDashboard/ViewBank")
);
const CssLoader = lazy(() => import("./_components/_common/CssLoader"));

const SpotPricing = lazy(() =>
  import("./_Pages/_warehouse/TradeProcurement/SpotPricing/SpotPricing")
);
const BuyerSpotPricing = lazy(() =>
  import("./_Pages/_warehouse/TradeProcurement/SpotPricing/BuyerSpotPricing")
);
const SellerInvoiceForm = lazy(() =>
  import("./_Pages/_warehouse/LtpStpInvoice/SellerInvoiceForm")
);

//startr of price capture
const PitchCustomerSelectCommodity = lazy(() =>
  import(
    "./_Pages/_webapp/priceCapture/PitchCustomerSelectCommodity/PitchCustomerSelectCommodity"
  )
);
const PitchCustomerSelectSeller = lazy(() =>
  import(
    "./_Pages/_webapp/priceCapture/PitchCustomerSelectSeller/PitchCustomerSelectSeller"
  )
);
const CreateCampaign = lazy(() =>
  import("./_Pages/_webapp/priceCapture/CreateCampaign/CreateCampaign")
);
const PitchCustomerReadyToSell = lazy(() =>
  import(
    "./_Pages/_webapp/priceCapture/PitchCustomerReadyToSell/PitchCustomerReadyToSell"
  )
);
const PitchCustomerViewRecentCampaign = lazy(() =>
  import(
    "./_Pages/_webapp/priceCapture/PitchCustomerViewRecentCampaign/PitchCustomerViewRecentCampaign"
  )
);

const BankDetailsVerification = lazy(() =>
  import("./_Pages/_webapp/BankDetailsVerification/BankDetailsVerification")
);

const TokenHistory = lazy(() =>
  import(
    "./_Pages/_webapp/arya2.0/Blockchain/Banker/TokenHistotry/TokenHistory"
  )
);
const BlockchainCustomizeFilter = lazy(() =>
  import(
    "./_Pages/_webapp/arya2.0/Blockchain/Banker/CustomizeLoanOffer/CustomizeLoanOffer"
  )
);

const NewBankerListingPage = lazy(() =>
  import(
    "./_Pages/_webapp/arya2.0/Blockchain/Banker/BankerListingPage/NewBankerListingPage"
  )
);

const NewMakeLoanOfferForm = lazy(() =>
  import(
    "./_Pages/_webapp/arya2.0/Blockchain/Banker/NewMakeLoanOfferForm/NewMakeLoanOfferForm"
  )
);

const BankerViewDetailsPage = lazy(() =>
  import(
    "./_Pages/_webapp/arya2.0/Blockchain/Banker/BankerViewDetailsPage/BankerViewDetailsPage"
  )
);

const CustomizeLoanOfferForm = lazy(() =>
  import(
    "./_Pages/_webapp/arya2.0/Blockchain/Banker/CustomizeLoanOffer/CustomizeLoanOfferForm/CustomizeLoanOfferForm"
  )
);

const PublicNewBankerListingPage = lazy(() =>
  import(
    "./_Pages/_webapp/arya2.0/Blockchain/Banker/PublicBankerListingPage/PublicNewBankerListingPage"
  )
);

const DocumentCenter = lazy(() =>
  import("./_Pages/_webapp/arya2.0/DocumentCenter/DocumentCenter")
);
// const MyStore = lazy(() =>
//   import("./_Pages/_webapp/arya2.0/Catalog/MyStore/MyStore")
// );
const BuyerViewSellerDetail = lazy(() =>
  import("./_Pages/_webapp/arya2.0/Catalog/BuyerViewOfSeller/Detail")
);

// const CatalogAddProduct = lazy(() =>
//   import("./_Pages/_webapp/arya2.0/Catalog/AddProduct/AddProduct")
// );
const BuyerViewSellerListing = lazy(() =>
  import("./_Pages/_webapp/arya2.0/Catalog/BuyerViewOfSeller/Listing")
);
const MyQuotes = lazy(() =>
  import("./_Pages/_webapp/arya2.0/MyQuotes/MyQuotes")
);
const InventoryListing = lazy(() =>
  import("./_Pages/_webapp/arya2.0/Inventory/InventoryListingPage")
);

const StpLtpTrade = lazy(
  () => import("./_Pages/_webapp/arya2.0/StpLtpTrade/StpLtpTrade")
  //stp-ltp
);
const StpLtpTradeBuyerInvoice = lazy(() =>
  import(
    "./_Pages/_webapp/arya2.0/StpLtpTrade/StpBuyerInvoiceListing/StpBuyerInvoiceListing"
  )
);
const StpLtpTradeSellerInvoice = lazy(() =>
  import(
    "./_Pages/_webapp/arya2.0/StpLtpTrade/StpSellerInvoiceListing/StpSellerInvoiceListing"
  )
);
const StpLtpTradeInvoiceDetail = lazy(() =>
  import(
    "./_Pages/_webapp/arya2.0/StpLtpTrade/InvoicePages/LtpStpInvoiceDetails/InvoiceDetails"
  )
);
const StpLtpTradeBuyerInvoiceDetail = lazy(() =>
  import(
    "./_Pages/_webapp/arya2.0/StpLtpTrade/InvoicePages/LtpStpBuyerInvoiceDetails/LtpStpBuyerInvoiceDetails"
  )
);
const StpLtpTradeContractDetails = lazy(() =>
  import("./_Pages/_webapp/arya2.0/StpLtpTrade/ContractDetail/ContractDetail")
);

const LTPReleaseStock = lazy(() =>
  import("./_Pages/_webapp/arya2.0/StpLtpTrade/ContractDetail/LTPReleaseStock")
);

const PendingInvoices = lazy(() =>
  import("./_Pages/_webapp/arya2.0/Home/InvoiceCard/InvoiceCardDetail")
);

const ServiceRequests = lazy(() =>
  import("./_Pages/_webapp/arya2.0/Home/ServiceRequest/ServiceRequestDetails")
);

const PaySTPLTPAmount = lazy(() =>
  import("./_Pages/_webapp/arya2.0/StpLtpTrade/STPLTPPayment/PaySTPLTP")
);

const StpVirtualSellerSummary = lazy(() =>
  import(
    "./_Pages/_webapp/arya2.0/StpLtpTrade/UploadInvoice/InvoiceSummary/StpVirtualSeller/StpVirtualSellerSummary"
  )
);
const StpVirtualBuyerSummary = lazy(() =>
  import(
    "./_Pages/_webapp/arya2.0/StpLtpTrade/UploadInvoice/InvoiceSummary/StpVirtualBuyer/StpVirtualBuyerSummary"
  )
);
const LTPBuyerPayMoneyMargin = lazy(() =>
  import(
    "./_Pages/_webapp/arya2.0/StpLtpTrade/LTPTrade/PayMoneyMargin/PayMoneyMargin.jsx"
  )
);
const LTPBuyerInvoice = lazy(() =>
  import(
    "./_Pages/_webapp/arya2.0/StpLtpTrade/LTPTrade/InvoiceDetails/InvoiceDetails.jsx"
  )
);
const LtpBuyerInvoiceListing = lazy(() =>
  import(
    "./_Pages/_webapp/arya2.0/StpLtpTrade/LTPTrade/InvoiceListing/InvoiceListing.jsx"
  )
);
const LtpBuyerInvoiceSummary = lazy(() =>
  import(
    "./_Pages/_webapp/arya2.0/StpLtpTrade/LTPTrade/LTPBuyInvoiceSummary/LTPBuyInvoiceSummary.jsx"
  )
);

const ApplyLoan = lazy(() =>
  import(
    "./_Pages/_webapp/arya2.0/Blockchain/Depositor/NewApplyLoanListingView/ApplyLoanPages/ApplyLoan"
  )
);

// end of price capture
toastr.options = {
  positionClass: "toast-bottom-right",
};

//broadcast-message

const BroadcastMessages = lazy(() =>
  import("./_Pages/_webapp/Broadcast/BroadcastMessages")
);
const NewsAndUpdate = lazy(() =>
  import("./_Pages/_webapp/arya2.0/StaticPages/NewsAndUpdate/NewsAndUpdate")
);

const RazorPayPaymentDashboard = lazy(() =>
  import(
    "./_Pages/_webapp/arya2.0/AccountSettings/PaymentDashbord/PaymentDashboard"
  )
);
const RazorPayPaymentDetail = lazy(() =>
  import(
    "./_Pages/_webapp/arya2.0/AccountSettings/PaymentDashbord/PaymentDetailPage/PaymentDetailPage"
  )
);
const TwoButtonDesktopModal = lazy(() =>
  import(
    "./_Pages/_webapp/arya2.0/StpLtpTrade/CommonComponents/TwoButtonDesktopModal/TwoButtonDesktopModal"
  )
);

const LTPSupplierInvoiceListing = lazy(() =>
  import(
    "./_Pages/_webapp/arya2.0/StpLtpTrade/CommonComponents/LTPSupplierInvoiceListing/LTPSupplierInvoiceListing.jsx"
  )
);
const LTPSupplierInvoiceSummary = lazy(() =>
  import(
    "./_Pages/_webapp/arya2.0/StpLtpTrade/LtpSupplierDetails/SupplierInvoiceSummary.jsx"
  )
);

const JobPostPortal = lazy(() =>
  import("./_Pages/_webapp/arya2.0/JobPostPortal/JobPostPortal.jsx")
);
const JobPortalForm = lazy(() =>
  import(
    "./_Pages/_webapp/arya2.0/JobPostPortal/JobPortalform/JobPortalForm.jsx"
  )
);
const STPOfferDetails = lazy(() =>
  import("./_Pages/_webapp/arya2.0/StpLtpTrade/STPAutoSanction/STPOfferDetails")
);
const UploadDocument = lazy(() =>
  import(
    "./_Pages/_webapp/arya2.0/Blockchain/Depositor/UploadDocumentForm/UploadDcoumentForm.jsx"
  )
);
const BlockchainBankerLogin = lazy(() =>
  import(
    "./_Pages/_webapp/arya2.0/Blockchain/Banker/BankerLogin/BankerLogin.jsx"
  )
);

const PaymentLedger = lazy(() =>
  import("./_Pages/_webapp/arya2.0/Finance/PaymentLedger/PaymentLedger.jsx")
);
const PaymentLedgerDetails = lazy(() =>
  import(
    "./_Pages/_webapp/arya2.0/Finance/PaymentLedger/PaymentLedgerDetails.jsx"
  )
);
const StorageContractDetail = lazy(() =>
  import(
    "./_Pages/_webapp/arya2.0/Storage/StorageContracts/StorageContractDetail.jsx"
  )
);

const STPAutoLeadRenewal = lazy(() =>
  import(
    "./_Pages/_webapp/arya2.0/StpLtpTrade/AutoLeadRenewal/STPAutoLeadRenewal"
  )
);

const FetchCreditScore = lazy(() =>
  import("./_Pages/_webapp/arya2.0/Trade/CreditScoreFlow/FetchCreditScore")
);
const CibilReports = lazy(() =>
  import("./_Pages/_webapp/arya2.0/Trade/CreditScoreFlow/CibilReports")
);
const CibilConfirmPayment = lazy(() =>
  import("./_Pages/_webapp/arya2.0/Trade/CreditScoreFlow/CibilConfirmPayment")
);

let { store } = configureStore();

function App() {
  window.scrollTo(0, -100);
  const history = createBrowserHistory();
  let path = window.location.pathname;
  let pathsArr = ["/authenticate"];
  let mbn = getQueryParam("secretKey");
  if (mbn) {
    localStorage.setItem("pathName", window.location.pathname);
    localStorage.setItem("ssecretKey", mbn);
  }
  const isMobile = window.matchMedia(
    "only screen and (max-width: 1023px)"
  ).matches;

  // let location = useLocation();/

  useEffect(() => {
    console.log("Location changed");
  }, [history]);

  useEffect(() => {
    if (!getCookie("Device-Id")) {
      setDeviceCookie();
    }
    if (!getCookie("arya_ag_cr_token")) {
      setCrTokenCookie();
    }
  }, []);

  const appContext = useAppContext();

  return (
    <Provider store={store}>
      <Router history={history}>
        <div className={appContext.app ? "App" : ""}>
          {!pathsArr.includes(path) && <CssLoader />}
          {/*<Header></Header> */}
          <ScrollHandling />
          <Suspense
            fallback={
              <>
                {!pathsArr.includes(path) && <DefaultLoader isLoading={true} />}
              </>
            }
          >
            <Switch>
              {/* Private Routes */}
              {/* <PrivateRoute path='/admin/dashboard' component={WelcomeDashBoard} /> */}
              <PrivateRoute path="/admin/dashboard" component={RoleDashboard} />
              <PrivateRoute
                path="/admin/enam-storage"
                component={EnamStorage}
              />
              {/* <PrivateRoute path='/Warehouse/:id' component={WarehouseDetail} /> */}
              {/* <PrivateRoute path='/compare-warehouse' component={CompareWarehouse} /> */}
              <PrivateRoute
                path="/my-requirements/:id"
                component={BuyerEnlistDetails}
              />
              <PrivateRoute
                path="/admin/buyer-requirements/:id"
                component={BuyerEnlistDetails}
              />
              <PrivateRoute
                path="/admin/addSubAdmin/:id"
                component={AddSubAdmin}
              />
              <PrivateRoute
                path="/admin/SubAdminPermission/:id"
                component={SubAdminPermission}
              />
              <PrivateRoute
                path="/admin/SubAdminServices/:id"
                component={SubAdminServices}
              />
              <PrivateRoute path="/admin/accountDetails" component={Profile} />
              <PrivateRoute path="/favWarehouse" component={FavWarehouse} />
              <PrivateRoute
                path="/bookedWarehouse"
                component={BookedWarehouse}
              />
              <PrivateRoute path="/MyWarehouse" component={MyWarehouse} />
              <PrivateRoute
                path="/admin/RequestForQuote/:id"
                component={RFQForUser}
              />
              <PrivateRoute
                path="/admin/RequestForQuoteUserListing"
                component={RFQForUserListing}
              />
              <PrivateRoute path="/admin/MakerList" component={MakerList} />
              <PrivateRoute
                path="/admin/SubAdminBuyAndSellListing"
                component={SubAdminBuyAndSellListing}
              />
              <PrivateRoute path="/admin/SellerInfo" component={SellerInfo} />
              <PrivateRoute
                path="/admin/UserBuyAndSellListing"
                component={UserBuyAndSellListing}
              />
              <PrivateRoute
                path="/admin/buy-sell-listing/:id"
                component={UserBuyAndSellListing}
              />
              <PrivateRoute
                path="/admin/negotiation/:id"
                component={Negotiable}
              />
              <PrivateRoute
                path="/admin/buySellNegotiableListing/:id"
                component={NegotiableRecoed}
              />
              <PrivateRoute
                path="/NegotiationCompleted"
                component={ThankYouNegotiation}
              />
              <PrivateRoute
                path="/admin/UserReportsform"
                component={ReportsForm}
              />
              <PrivateRoute path="/reports" component={Reports} />
              <PrivateRoute
                path="/user/finance/loan-against-commodity"
                component={Finances}
              />
              <PrivateRoute path="/sell" component={BuySellUser} />
              <PrivateRoute
                path="/finance-list"
                component={UserFinanceListing}
              />
              <PrivateRoute path="/my-booking" component={MyBooking} />
              <PrivateRoute path="/rent-log" component={ReleaseStockLog} />
              <LoginMandatoryRoute
                path="/release-order"
                component={OfflinePaymentLog}
              />
              <PrivateRoute
                path="/my-loans-requests"
                component={MyLoansRequest}
              />
              <PrivateRoute path="/company-users" component={CompanyUsers} />
              <PrivateRoute
                path="/admin/company-users/:id"
                component={CompanyUsersListingSuperAdmin}
              />
              <PrivateRoute
                path="/add-company-users"
                component={AddCompanyUSers}
              />
              <PrivateRoute
                path="/update-company-users"
                component={AddCompanyUSers}
              />
              <PrivateRoute
                path="/my-requirements"
                component={BuyerEnlistListing}
              />
              <PrivateRoute path="/manage-documents" component={ManageDoc} />
              <PrivateRoute path="/my-stock" component={MyStockListing} />
              <PrivateRoute path="/release-stock" component={storage} />
              <PrivateRoute path="/ltp-outward" component={LtpOutward} />
              <PrivateRoute
                path="/admin/release-stock"
                component={ReleaseStockByAdmin}
              />
              <PrivateRoute path="/view-bank" component={ViewBank} />
              <PrivateRoute
                path="/buyerspotpricing"
                component={BuyerSpotPricing}
                exact
              />
              <PrivateRoute
                path="/supplier-spot-pricing"
                component={SupplierSpotPricing}
                exact
              />
              <PrivateRoute
                path="/buyerspotpricing/:id"
                component={BuyerSpotPricing}
              />
              <PrivateRoute
                path="/manage-supplier-quote"
                component={ManageSupplierQuote}
              />
              <PrivateRoute
                exact
                path="/release-stock-payments/:id"
                component={RazorPayPaymentDetail}
              />
              <PrivateRoute
                exact
                path="/release-stock-payments"
                component={RazorPayPaymentDashboard}
              />
              <PrivateRoute exact path="/job-post" component={JobPostPortal} />
              <PrivateRoute
                exact
                path="/job-post/add"
                component={JobPortalForm}
              />
              <PrivateRoute
                exact
                path="/job-details/:id"
                component={JobPortalForm}
              />
              <PrivateRoute
                exact
                path="/job-post/edit/:id"
                component={JobPortalForm}
              />
              {/* BANKER Routes */}
              <BankerRoute path="/test" component={Warehouse} />
              {/* SUPER ADMIN Routes */}
              <SuperAdminRoute
                path="/admin/warehouseRequest"
                component={WarehouseRequest}
              />
              <SuperAdminRoute
                path="/admin/warehouseRequestLogs/:id"
                component={WhLog}
              />
              <SuperAdminRoute
                path="/admin/warehouseRequestBookingLogs/:id"
                component={WhLogBooking}
              />
              <SuperAdminRoute
                path="/admin/ServiceRequestLogs/:id/:code"
                component={ServiceRequestLogs}
              />
              <SuperAdminRoute
                path="/admin/mywarehouseRequest/:admin"
                component={WarehouseRequest}
              />
              <SuperAdminRoute path="/admin/reachout" component={ReachOut} />
              <SuperAdminRoute
                path="/admin/arya-paysafe"
                component={AryaPaysafe}
              />
              <SuperAdminRoute
                path="/admin/line-for-credit"
                component={LineForCredit}
              />
              <SuperAdminRoute
                path="/WarehouseCreationForm/:id"
                component={WarehouseCreationForm}
              />
              <SuperAdminRoute
                path="/WarehouseEditForm/:id"
                component={WarehouseCreationForm}
              />
              <SuperAdminRoute
                path="/WarehouseEditFormByAdmin/:wh_id"
                component={WarehouseCreationForm}
              />
              <SuperAdminRoute
                path="/admin/warehouseListing"
                component={WarehouseListing}
              />
              <SuperAdminRoute
                path="/admin/warehouseImageSequencing/:id"
                component={ImageSequence}
              />
              <SuperAdminRoute
                path="/admin/warehouseImport"
                component={WarehouseImport}
              />
              <SuperAdminRoute path="/admin/cmsListing" component={CMS} />
              <SuperAdminRoute path="/admin/cmsEdit/:id" component={CMSEdit} />
              <SuperAdminRoute
                path="/admin/news-updates"
                component={NewsAndUpdates}
              />
              <SuperAdminRoute
                path="/admin/add-news-updates"
                component={NewsAndUpdatesForm}
              />
              <SuperAdminRoute
                path="/admin/research-insights"
                component={ResearchInsights}
              />
              <SuperAdminRoute
                path="/admin/add-research-insights"
                component={ResearchInsightsFrom}
              />
              <SuperAdminRoute path="/admin/users" component={AllUserListing} />
              <SuperAdminRoute path="/admin/SubAdmin" component={SubAdmin} />
              <SuperAdminRoute
                path="/admin/all-other-users"
                component={AllOtherUsers}
              />
              <SuperAdminRoute
                path="/admin/AllChecker"
                component={AllChecker}
              />
              <SuperAdminRoute path="/admin/MediaCms" component={MediaCms} />
              <SuperAdminRoute
                path="/admin/AddMediaCms/:id"
                component={AddCmsMedia}
              />
              <SuperAdminRoute
                path="/admin/setting-list"
                component={SettingList}
              />
              <SuperAdminRoute
                path="/admin/add-update-setting/:id"
                component={AddSetting}
              />
              <SuperAdminRoute
                path="/admin/bookedListing"
                component={BookedWarehouseListing}
              />
              <SuperAdminRoute
                path="/admin/AdminBuyAndSellListing"
                component={AdminBuyAndSellListing}
              />
              <SuperAdminRoute
                path="/admin/serviceRequest"
                component={ServiceRequestListing}
              />
              <SuperAdminRoute
                path="/admin/GroupsList"
                component={GroupsList}
              />
              <SuperAdminRoute
                path="/admin/EditUserGroup/:id"
                component={EditUserGroup}
              />
              <SuperAdminRoute
                path="/admin/AllServiceRequestList"
                component={AllServiceRequestList}
              />
              <SuperAdminRoute
                path="/admin/AddUpdateServiceRequest/:id"
                component={AddUpdateServiceRequest}
              />
              <SuperAdminRoute
                path="/admin/AddUpdateCluster/:id"
                component={AddCluster}
              />
              <SuperAdminRoute
                path="/admin/allCluster"
                component={AllCluster}
              />
              <SuperAdminRoute
                path="/admin/RFQListing"
                component={RFQListing}
              />
              <SuperAdminRoute path="/admin/addRFQ/:id" component={addRFQ} />
              <SuperAdminRoute
                path="/admin/RequestForQuote"
                component={RFQForUser}
              />
              <SuperAdminRoute
                path="/admin/ClusterUsers/:id"
                component={ClusterUsers}
              />
              <SuperAdminRoute
                path="/admin/userEdit/:id"
                component={UserEditByAdmin}
              />
              <SuperAdminRoute
                path="/admin/AddUpdateChecker/:id"
                component={AddUpdateChecker}
              />
              <SuperAdminRoute
                path="/admin/Subscription"
                component={Subscription}
              />
              <SuperAdminRoute
                path="/admin/AllUserPlanListing"
                component={AllUserPlanListing}
              />
              <SuperAdminRoute
                path="/admin/ConfigurationList"
                component={ConfigurationList}
              />
              <SuperAdminRoute
                path="/admin/AddUpdateSubscrioption/:id"
                component={AddSubsription}
              />
              <SuperAdminRoute
                path="/admin/AddUpdateWorkingDays/:id"
                component={ManageDays}
              />
              <SuperAdminRoute
                path="/admin/HolidaysListing"
                component={HolidaysListing}
              />
              <SuperAdminRoute
                path="/admin/workingDaysList"
                component={workingDaysList}
              />
              <SuperAdminRoute
                path="/admin/AddRequestConfiguration/:id/:flag"
                component={AddRequestConfiguration}
              />
              <SuperAdminRoute
                path="/admin/AddAndUpdateCommodityParameter"
                component={CommodityParameter}
              />
              <SuperAdminRoute
                path="/commodity-landing-page-listing"
                component={WarehouseLandingPageListing}
              />
              <SuperAdminRoute
                path="/admin/contract"
                component={ContractMasterForm}
              />
              <SuperAdminRoute
                path="/admin/all-user-finance-request"
                component={FinanceListingForSuperAdmin}
              />
              <SuperAdminRoute
                path="/admin/finance-request-logs/:id/:code"
                component={WhLogFinance}
              />
              <SuperAdminRoute
                path="/admin/add-new-user"
                component={SignUpUserBySubadmin}
              />
              <SuperAdminRoute
                path="/admin/add-user/:admin"
                component={SignUpUserBySubadmin}
              />
              <SuperAdminRoute
                path="/admin/add-users"
                component={SignUpUserBySubadmin}
              />
              <SuperAdminRoute
                path="/admin/editUser"
                component={EditUserByadmin}
              />
              <SuperAdminRoute
                path="/admin/contract-list"
                component={ContractBySubadmin}
              />
              <SuperAdminRoute
                path="/admin/pwh-contract"
                component={PwhContractBySubadmin}
              />
              <SuperAdminRoute
                path="/admin/pwh-contracts/edit"
                component={ContractForm}
              />
              <SuperAdminRoute
                path="/admin/pwh-contracts/view"
                component={PwhContractView}
              />
              <SuperAdminRoute
                path="/admin/:type/contract-list"
                component={ContractByBDLTPSTP}
              />
              <SuperAdminRoute
                path="/admin/ltp-stp/dashboard"
                component={BDLTPSTPDashboard}
              />
              <SuperAdminRoute
                path="/admin/ltp-stp/invoice-form"
                component={BDLTPSTPInvoiceForm}
              />
              {/* <Route
                path="/admin/ltp-stp/invoice-form"
                render={() => <Redirect to="/404" />}
              /> */}
              <SuperAdminRoute
                path="/admin/ltp-stp/invoice-details"
                component={BDLTPSTPInvoiceView}
              />
              {/* <SuperAdminRoute path="/admin/:type/contract-list" component={ContractByBDLTPSTP} /> */}
              <SuperAdminRoute
                path="/admin/payment-logs"
                component={PaymentLogs}
              />
              <SuperAdminRoute
                path="/admin/create-contract-form"
                component={ContractForm}
              />
              <SuperAdminRoute
                path="/admin/ltp-stp/invoice-list"
                component={BDLTPSTPInvoice}
              />
              <SuperAdminRoute
                path="/admin/contracts/:type/view"
                component={STPLTPContractView}
              />
              <SuperAdminRoute
                path="/admin/contracts-details/:type/:view"
                component={STPLTPContractView}
              />
              <SuperAdminRoute
                path="/admin/contract-details/:type/:id"
                component={STPLTPContractView}
              />
              <SuperAdminRoute
                path="/admin/create-contracts"
                component={ContractFormInd}
              />
              <SuperAdminRoute
                path="/admin/bd-spot-pricing"
                component={BdUserSpotPricing}
              />
              <SuperAdminRoute
                path="/admin/bd-manage-quotation"
                component={BdManageQuotations}
              />
              <SuperAdminRoute
                path="/admin/all-commodities"
                component={CommodityListing}
              />
              <SuperAdminRoute path="/user-otp" component={UserOTP} />
              <SuperAdminRoute
                path="/admin/buyer-requirements"
                component={BuyerLeadsListing}
              />
              <SuperAdminRoute
                path="/admin/post-your-requirements"
                component={CreateLeadsByAdmin}
              />
              <LoginMandatoryRoute
                path="/blockchain/customize-filter"
                component={BlockchainCustomizeFilter}
              />
              <Route
                path="/disbursement/linked-tokens"
                component={ListDisbursementToken}
              />

              <Route path="/commodity-details" component={CommodityDetails} />

              <LoginMandatoryRoute
                path="/sell-stock-listing"
                component={SellStockListing}
              />

              <LoginMandatoryRoute
                component={MobileLoanDetail}
                path="/disbursement-details/:id"
                // component={LoanDetail}
              />
              <LoginMandatoryRoute
                path="/ltp-stp-buyer-invoice-detail"
                component={StpLtpTradeBuyerInvoiceDetail}
                exact
              />
              <LoginMandatoryRoute
                path="/disbursement/:id"
                component={MobileDisbursementDetail}
              />
              <LoginMandatoryRoute
                exact
                path="/finance/active-loan/:id"
                component={LoanStatement}
              />
              <LoginMandatoryRoute
                exact
                path="/finance/loan-requests"
                component={LoanRequests}
              />
              <LoginMandatoryRoute
                exact
                path="/my-storage/transfer-requests"
                component={storage}
              />
              <LoginMandatoryRoute
                exact
                path="/my-storage/warehouse/:id"
                component={myWarehouse}
              />
              <LoginMandatoryRoute
                exact
                path="/arya2/release-stock/:id/:id/:id"
                component={release_stock}
              />
              <LoginMandatoryRoute
                path="/post-your-requirements"
                component={PostYourReq}
              />
              <LoginMandatoryRoute
                TYPE={"other"}
                path="/blockchain/depositor/:tab"
                component={NewDepositorListingPage}
              />
              <LoginMandatoryRoute
                path="/stpltp/contract-detail/:id"
                component={StpLtpTradeContractDetails}
              />
              <LoginMandatoryRoute
                exact
                path="/forward-trade/:id"
                component={isMobile ? ForwardTradeDetails : ForwardTrade}
              />
              <LoginMandatoryRoute
                path="/view-contract/:id"
                component={TradeContract}
              />
              <LoginMandatoryRoute
                exact
                path="/create-contract/:id"
                component={CreateContract}
              />
              <LoginMandatoryRoute
                path="/buyer-invoice-list/:id"
                component={StpLtpTradeBuyerInvoice}
                exact
              />
              <Route
                path="/warehouse/find-a-warehouse"
                component={mobileWarehouse}
              />
              <Route
                path="/warehouse/:id/:id"
                component={mobileBookWarehouse}
                exact
              />
              {/* <Route
                exact
                path="/loan-against-commodity-Page-Form"
                component={LACPageForm}
              /> */}

              {/* <Route exact path="/homepagerevamp" component={MyActiveLoans} /> */}

              <LoginMandatoryRoute
                exact
                path="/release-stock-log"
                component={ReleaseStockLog}
              />
              <LoginMandatoryRoute
                exact
                path="/my-quotes"
                component={MyQuotes}
              />
              <LoginMandatoryRoute
                exact
                path="/my-wishlist"
                component={MyWishlist}
              />
              <LoginMandatoryRoute
                exact
                path="/my-profile"
                component={Profile2}
              />
              <LoginMandatoryRoute
                path="/document-center"
                component={DocumentCenter}
              />
              {/* <LoginMandatoryRoute exact path="/my-store" component={MyStore} /> */}
              <LoginMandatoryRoute
                path="/manage-profile/general-details"
                component={GeneralAccountSettings}
                exact
              />
              <LoginMandatoryRoute
                path="/manage-profile/manage-users"
                component={ManageUsers}
              />

              <LoginMandatoryRoute
                path="/manage-profile/manage-notifications"
                component={ManageNotification}
                exact
              />
              <LoginMandatoryRoute
                path="/manage-profile/commodity-preferences"
                component={CommodityLocationPreferencesSettings}
                exact
              />
              <LoginMandatoryRoute
                path="/manage-profile/language-preferences"
                component={LanguagePreferencesSettings}
                exact
              />
              <LoginMandatoryRoute
                path="/manage-profile/notifications"
                component={NotificationPreferences}
                exact
              />
              <LoginMandatoryRoute
                path="/manage-profile/bank-details"
                component={BankDetailSetting}
                exact
              />
              <LoginMandatoryRoute
                path="/manage-profile/trade-details"
                component={TradeDetailSetting}
                exact
              />
              {/* <LoginMandatoryRoute
                exact
                path="/create-auction"
                component={CreateAuctions}
              />
              <LoginMandatoryRoute
                exact
                path="/auction-details/:id"
                component={AuctionDetails}
              />
              <LoginMandatoryRoute
                exact
                path="/all-auctions"
                component={AllAuctions}
              />  */}
              <LoginMandatoryRoute
                path="/manage-profile"
                component={
                  isMobile ? ManageUserAccountSetting : AccountMenu
                  // ManageProfile
                }
                exact
              />
              <LoginMandatoryRoute
                exact
                path="/get-loan-disbursement"
                component={getLoanDisbursement}
              />
              <LoginMandatoryRoute
                path="/invoice-file-upload-form/:id"
                component={UploadInvoice}
              />
              <LoginMandatoryRoute
                path="/trade/stp/pay-margin/:contractId"
                component={PaySTPMargin}
              />

              <LoginMandatoryRoute
                path="/ltp-invoice-file-upload-form/:id"
                component={LTPUploadInvoice}
              />

              <LoginMandatoryRoute
                path="/seller-invoice-list/:id"
                component={StpLtpTradeSellerInvoice}
                exact
              />
              <LoginMandatoryRoute
                path="/ltp-stp-invoice-detail"
                component={StpLtpTradeInvoiceDetail}
                exact
              />

              <LoginMandatoryRoute
                path="/finance/payment-ledger"
                component={PaymentLedger}
                exact
              />
              <LoginMandatoryRoute
                path="/finance/payment-ledger-details"
                component={PaymentLedgerDetails}
                exact
              />
              <LoginMandatoryRoute
                path="/storage-contract-detail/:contractId"
                component={StorageContractDetail}
                exact
              />

              {/* WH OWNER Routes */}
              {/* <WarehouseOwnerRoute path='/warehouse/enlist-warehouse' component={WarehouseRequestForm} />
              <WarehouseOwnerRoute path='/EnlistThankYou' component={EnlistThankYou} /> */}
              {/* Public Routes */}
              <LoginMandatoryRoute
                path="/warehouse/enlist-warehouse"
                component={WarehouseRequestForm}
              />
              <Route exact path="/" component={newArya} />
              <Route
                exact
                path="/arya-business-model"
                component={BuisnessNeed}
              />
              <Route exact path="/popular-lead" component={PopularLead} />
              <Route
                exact
                path="/popular-lead/:type/"
                component={PopularLead}
              />
              <Route
                exact
                path="/account-settings2"
                component={AccountSettings}
              />
              <Route exact path="/select-supply" component={SellerView} />
              <Route exact path="/trade" component={Listing} />
              <Route exact path="/trade/:child" component={Listing} />
              {/* <Route exact path="/my-bids" component={MyAllBids} />
              <Route exact path="/my-auctions" component={MyAuctions} />
              <Route
                exact
                path="/auction-bid-history/:id"
                component={BidHistoryPage}
              /> */}
              <Route
                exact
                path="/lead-detail/:createdById/:id/"
                component={LeadDetail}
              />
              <Route
                exact
                path="/contract-summary/:id"
                component={ContractSummary}
              />
              {/* <Route exact path="/direct-contract" component={DirectTrade} /> */}
              <Route exact path="/post-your-req" component={PostYourReq} />
              <Route exact path="/arya2profile" component={newArya2} />
              <Route exact path="/who-we-serve" component={whoweserve} />
              <Route exact path="/my-storage/commodities" component={storage} />
              <Route
                exact
                path="/my-storage/transfer-request/:id"
                component={transferDetail}
              />
              <Route exact path="/my-storage/warehouses" component={storage} />
              <Route exact path="/stock-ledger" component={StockLedger} />

              <Route exact path="/my-storage/invoices" component={storage} />
              <Route exact path="/my-storage/contracts" component={storage} />
              <Route
                exact
                path="/my-storage/bookingRequest"
                component={storage}
              />
              <Route
                exact
                path="/my-storage/invoices-historical"
                component={storageListing}
              />
              <Route exact path="/finance" component={MyFinance} />
              {/* <Route
                exact
                path="/disbursements"
                component={DisbursementListing}
              /> */}
              {/* <Route exact path="/arya-vision" component={AryaVision} /> */}
              {/* <Route
                exact
                path="/browse-by-commodity"
                component={browseByCommodity}
              /> */}
              <Route exact path="/arya-shakti" component={AryaShakti} />
              {/* <Route exact path="/arya2/bnpl" component={bnpl} /> */}
              <Route
                exact
                path="/supplies_demands"
                component={supplies_demands}
              />
              <Route
                exact
                path="/loan-against-commodity-new"
                component={LacPage}
              />
              {/* <Route exact path="/e-auction" component={EAuction} /> */}
              {/* <Route exact path="/find-warehouse" component={FindWarehouse} /> */}
              {/* <Route
                exact
                path="/finance/statement"
                component={WalletStatement}
              /> */}

              <Route exact path="/price-trend" component={PriceTrend} />
              {/* <Route exact path="/menu-app" component={AccountMenu} />
              <Route
                exact
                path="/account-manage"
                component={ManageUserAccountSetting}
              /> */}

              <Route
                exact
                path="/finance/disbursement-requests"
                component={DisbursementRequests}
              />
              <Route exact path="/download-app" component={DownloadApp} />
              <Route
                exact
                path="/download-aryashakti-app"
                component={DownloadAryaShakti}
              />
              <Route
                exact
                path="/commonDoughnutChart"
                component={commonDoughnutChart}
              />
              <Route exact path="/barChart" component={barChart} />

              <Route
                exact
                path="/finance/active-loans"
                component={ActiveLoans}
              />
              <LoginMandatoryRoute
                exact
                path="/loan-against-commodity-Page-Form"
                component={LACPageNewForm}
              />
              {/* <Route
                exact
                path="/disbursement-request-listing"
                component={DisbursementRequestListing}
              /> */}
              {/* <Route exact path="/finance/pay" component={PayWalletAmount} /> */}
              {/* <Route exact path="/finance/wallet" component={CreditDetail} /> */}
              {/* <Route
                exact
                path="/finance/transaction"
                component={TransactionLedger}
              /> */}
              {/* <Route
                exact
                path="/finance/credit-wallet"
                component={CreditWallet}
              /> */}
              {/* <Route exact path="/media-coverage" component={mediaCoverage} /> */}
              {/* <Route
                exact
                path="/mandi-bhav/:commodity/:city"
                component={MandiBhavDetail}
              /> */}
              <Route
                exact
                path="/seller-catalog/:id"
                component={BuyerViewSellerListing}
              />
              {/* <Route
                exact
                path="/catalog/add-product"
                component={CatalogAddProduct}
              /> */}
              {/* <Route
                exact
                path="/catalog/edit-product/:id"
                component={CatalogAddProduct}
              /> */}
              {/* <Route exact path="/products" component={InventoryListing} /> */}

              <LoginMandatoryRoute
                exact
                path="/my-storage/commodity/:id/:id/:id"
                component={myCommodity}
              />
              <Route
                exact
                path="/warehouse/detail/:name/:id"
                component={bookWarehouseDetails}
              />
              <Route
                exact
                path="/paymentComponent"
                component={paymentComponent}
              />
              <Redirect
                exact
                from="/kharif-2022"
                to="/produce-estimate/login"
              />
              <Route
                exact
                path="/produce-estimate/login"
                component={LoginProduceEstimate}
              />
              <Route
                exact
                path="/produce-estimate/main"
                component={MainProductEstimate}
              />
              <Route
                exact
                path="/produce-estimate/rabi"
                component={MainProductEstimate}
              />
              <Route
                exact
                path="/finance/loan-against-commodity"
                component={FinanceForm}
              />
              {/* <Route exact path="/lead-signup" component={LeadSignUp} /> */}
              <Route exact path="/Dashboard" component={Dashboard} />
              <Route path="/m-login" component={AppSignUp} />
              {/* <Route path="/app-login" component={AppSignUp} /> */}
              <Route path="/warehouse/:id" component={WarehouseDetail} exact />
              <Route
                path="/WarehouseDocument/:id"
                component={WarehouseDetailPDF}
              />
              {/* <Route path="/createAuction" component={AuctionCreation} />
              <Route
                path={PATHS.AUCTIONS_WITH_COMMODITY}
                component={AuctionsList}
              />
              <Route path="/auctions" component={AuctionsList} />
              <Route path="/auction/:id" component={AuctionDetail} />
              <Route path="/mytrade" component={MyTrade} />
              <Route path="/myBids" component={MyBids} /> */}
              {/* Our Services Path*/}
              <Route
                path="/our-services/Consulting"
                component={ConsultingPage}
                // component={isMobile ? ConsultingPage : Consulting}
                exact
              />
              <Route
                path="/our-services/Procurement-Facilitation"
                component={Procrument}
              />

              <Route path="/blockchain/applyLoan" component={ApplyLoan} />

              <Route
                path="/our-services/Audits-Serveillance"
                component={AuditsPage}
                // component={isMobile ? AuditsPage : Audits}
                exact
              />
              <Route
                component={StorageLogisticPage}
                // component={isMobile ? StorageLogisticPage : Storage}
                exact
                path="/our-services/storage-logistics"
              />
              <Route path="/our-services" component={Ourservices} />
              <Route path="/market/:id" component={MarketOpenUrl} />
              <Route path="/logout" component={Logout} />
              {/* Our Services Path End*/}
              {/* who we serve */}
              <Route
                path="/WhoWeServe/DevelopmentActors"
                component={WweDevelopmentActors}
              />

              <Route
                path="/WhoWeServe/InternationalBuyer"
                component={WweInternationalBuyer}
              />
              <Route
                path="/WhoWeServe/Agri_corporation"
                component={WweAgriCorporation}
              />
              <Route path="/WhoWeServe/Financial" component={WweFinancial} />
              <Route path="/WhoWeServe/Processor" component={WweProcessor} />
              <Route path="/WhoWeServe" component={WhoWeServe} />
              <Route path="/WhoWeServeFPO" component={WhoWeServeFPO} />
              {/* who we serve End*/}
              {/* News and Update */}
              <Route
                path="/news-update"
                component={isMobile ? mediaCoverage : NewsAndUpdate}
              />
              <Route
                path="/research-insights/:title"
                component={ResearchInsight}
              />
              {/* <Route path='/research-insights' component={ResearchInsight} /> */}
              <Route
                path="/internal-retail-listing"
                component={InternalRetailListing}
              />
              <Route path="/admin/arya-pro-logs" component={AryaproLogs} />
              <Route path="/warehouse-details" component={WarehouseDetail} />
              <Route path="/404" component={NoPageFound} />
              <Route path="/Mywarehouses" component={Mywarehouses} />
              <Route path="/WarehousesName/:id" component={WarehousesName} />
              <Route
                path="/our-story"
                component={AboutArya}
                // component={isMobile ? OurStory : AboutArya}
              />
              <Route path="/our-story/:id" component={OurStory} />
              {/* <Route
                path="/post-your-requirements"
                component={isMobile ? PostYourReq : BuyInlistForm}
              /> */}
              {/* <Route
                path="/buyer-requirements/:id"
                component={isMobile ? LeadDetail : BuyLeadDetails}
              /> */}
              <Route path="/buyer-requirements/:id" component={LeadDetail} />
              <Route path="/quote-details/:id" component={BuyLeadDetails} />
              <Route path="/about-a2z/:id" component={AboutA2Z} />
              <Route path="/about-a2z" component={AboutA2Z} />
              <Route path="/contact-us-old" component={ContactUs} />
              <Route path="/contact-us" component={ContactUsArya} />
              <Route
                path="/finance/arya-paysafe-escrow"
                component={EscrowFinance}
              />
              <Route
                path="/live-warehouse-video/:id"
                component={LiveWarehouseVideo}
              />
              {/* <Route
                path="/finance/line-of-credit"
                component={bnpl}
                // component={isMobile ? bnpl : BuyNowPayLater}
              /> */}
              <Route path="/warehouse-receipt-finance" component={Finance} />
              <Route
                path="/warehouse-management"
                // component={isMobile ? FindWarehouse : WarehouseManagement}
                component={FindWarehouse}
              />
              <Route
                path="/commodity-market-place"
                component={CommodityMarketPlace}
              />
              <Route
                path="/warehouse-surveillance"
                component={AryaVision}
                // component={WarehouseSurveillance}
              />
              {/* <Route path="/arjun" component={isMobile ? ArjunPage : Arjun} /> */}
              <Route path="/arjun" component={ArjunPage} />

              <Route
                path="/Ltp-ReleaseCommodity"
                component={
                  isMobile ? MobileLTPReleaseCommodity : LTPReleaseCommodity
                }
              />
              <Route
                path="/Ltp-ProformaInvoice"
                component={
                  isMobile ? MobileLtpProformaInvoice : LtpProformaInvoice
                }
              />

              <Route path="/career" component={CareerPage} exact />
              <Route path="/career/:id" component={CareerDetailPage} exact />
              <Route path="/arya-turns-10" component={AryaTurns10} />
              <Route
                path={PATHS.PRODUCTS_WITH_COMMODITY}
                component={MarketPlace}
              />
              {/* <Route path={PATHS.PRODUCTS_PATH} component={InventoryListing} /> */}
              {/* <Route path="/product/:id" component={BuyerViewSellerDetail} /> */}
              <Route path="/contact-seller" component={ContactSeller} />
              {/* <PrivateRoute
                path="/manage-products"
                component={ManageProducts}
              />
              <PrivateRoute path="/add-product" component={AddProduct} />
              <PrivateRoute path="/edit-product" component={EditProduct} /> */}
              <Route
                path="/post-sell-requirement"
                component={PostSellRequirement}
              />
              <Route
                path="/manage-requirements"
                component={ManageRequirements}
              />
              <Route path="/requirement-detail" component={RequirementDetail} />
              <Route path="/seller/:id" component={sellerDetails} />
              <Route
                path="/commodity-market-place-buy-sell/:type"
                component={CommodityMarketPlaceBuySell}
              />
              <Route
                path="/commodity-market-place-buy-sell"
                component={CommodityMarketPlaceBuySell}
              />

              <Route
                path="/quote-requested-successful"
                component={QuoteSuccess}
              />
              <Route path="/buy-sell-form" component={BuySellForm} />
              <Route path="/insurance-solutions" component={Insurance} />
              <Route
                path="/warehouse-monitoring-system"
                component={Monitoring}
              />
              <Route path="/quality-testing" component={QualityTesting} />
              <Route path="/payment-status-dtr" component={PaymentPopUp} />
              <Route
                path="/logistic-handling"
                component={LogisticAndHandling}
              />
              <Route path="/fumigation-service" component={Fumigation} />
              <Route path="/farmer-fpos" component={FarmerFpos} />
              <Route path="/banks" component={Banks} />
              <Route path="/government" component={Government} />
              <Route path="/corporates" component={Corporates} />
              <Route path="/internal/add-mandi-rate" component={AddMandiRate} />
              <Route
                path="/internal/view-mandi-rate"
                component={ViewMandiRate}
              />
              <Route path="/processers-traders" component={ProcessersTraders} />
              <Route path="/map" component={Map} />
              <Route path="/role-permission" component={RolePermission} />
              <Route path="/ServiceRequest" component={ServiceRequest} />
              <Route path="/CartSummary" component={CartSummary} />
              <Route path="/reports" component={Reports} />
              <Route path="/lead-thank-you" component={ThankYouNegotiation} />
              <Route
                path="/PaymentSuccessfull"
                component={PaymentSuccessfull}
              />
              <Route
                path="/payment-successfull/:id"
                component={PaymentSuccessfull}
              />
              <Route
                path="/PaymentSuccessfullPlan"
                component={PaymentSuccessfullPlan}
              />
              <Route path="/PaymentFailed" component={PaymentFailed} />
              <Route path="/privacy-policy" component={PrivacyPolicy} />
              <Route path="/terms-condition" component={TermsConditionsNotes} />
              <Route path="/help-us" component={ContactUsHelpSupport} />
              <Route path="/booking-process" component={BookingProcess} />
              <Route path="/terms" component={TermsConditions} />
              <Route path="/compare-warehouse" component={CompareWarehouse} />
              <Route path="/WarehouseBooking" component={WarehouseBooking} />
              <Route path="/MyWarehouse" component={OwnerWarehouse} />
              <Route
                path="/SubscriptionList"
                component={SubscriptionUserList}
              />
              <Route path="/CartSummaryOfPlan" component={CartSummaryOfPlan} />
              <Route path="/UserPlanHistory" component={UserPlanHistory} />
              <Route path="/commodity-landing-page" component={LandingPage} />
              <Route path="/EnlistThankYou" component={EnlistThankYou} />
              {/* <Route path="/ContractDetails/:id" component={ContractDetails} /> */}
              <Route path="/sitemap.html" component={Sitemap} />
              <Route path="/user-contacts" component={UserContractListing} />
              <Route path="/my-loan" component={MyLoanDataListing} />
              <Route path="/payments" component={MyReleaseOrder} />
              <Route path="/authenticate" component={Authontication} />
              <PrivateRoute path="/thankyoupage" component={ThankYouPage} />
              <Route
                path={PATHS.BUY_REQUIREMENT_WITH_COMMODITY}
                component={SearchLeads}
              />
              <Route
                path={PATHS.SELL_REQUIREMENT_WITH_COMMODITY}
                component={SearchBuyLead}
              />
              {/* <Route
                path={PATHS.BUY_REQUIREMENT_PATH}
                component={isMobile ? PopularLead : SearchLeads}
              /> */}
              <Route
                path={PATHS.BUY_REQUIREMENT_PATH}
                component={PopularLead}
              />
              <Route path={PATHS.SELL_REQUIREMENT} component={PopularLead} />
              {/* <Route
                path={PATHS.SELL_REQUIREMENT}
                component={isMobile ? PopularLead : SearchBuyLead}
              /> */}
              <Route
                path="/broadcast-campaign"
                exact
                component={BroadcastMessages}
              />
              <Route path="/br:id" component={Testing} />
              {/* <Route path='/payment-trade-confirmation' component={RoConfirmPayment} /> */}
              <Route path="/payment-confirmation" component={ConfirmPayment} />
              <Route
                path="/payment-ro-confirmation"
                component={RoConfirmPayment}
              />
              <Route
                path="/payment-rent-confirmation"
                component={RentPaymentConfirmation}
              />
              <Route
                path="/payment-trade-confirmation"
                component={PaymentSuccess}
              />
              <Route
                path={`/trade-payment-confirmation`}
                component={PaymentSuccess}
              />
              <Route
                path="/payment-credit-wallet-confirmation"
                component={CreditWalletConfirmPayment}
              />
              {/* <Route
                path="/payment-auction-confirmation"
                component={AuctionConfirmPayment}
              /> */}
              <Route
                path="/payment-stp-confirmation"
                component={StpConfirmPayment}
              />
              <Route
                path="/payment-cibil-confirmation"
                component={CibilConfirmPayment}
              />
              <Route
                path="/mandi-price/seller-inventory/view-chart"
                component={GraphMandi}
              />
              <Route
                path="/mandi-price/:state/:location/:commodity/view-chart"
                component={GraphMandi}
              />
              <Route
                path="/mandi-price/seller-inventory/edit"
                component={SellerEdit}
              />
              <Route
                path="/mandi-price/seller-inventory"
                component={MandiSell}
              />
              <Route path="/mandi-price" component={MandiPrice} />
              <Route
                path="/blockchain/token-history/:id"
                component={TokenHistory}
                exact
              />
              {/* <Route
                path="/blockchain/cus-filter"
                component={BlockchainCustomizeFilter}
                exact
              /> */}
              <Route
                path="/blockchain/public-list"
                component={BlockChainPublicList}
                exact
              />
              <Route
                path="/blockchain/public-details"
                component={BlockChainPublicDetail}
                exact
              />
              <Route
                path="/SellStock_BuyerDetails"
                component={GetBuyerDetails}
                exact
              />
              <Route
                path="/Commodity_SellStock_BuyerForm/:id"
                component={SellStockBuyerForm}
                exact
              />
              <Route
                path="/Commodity_SellStock_MatchedBuyers"
                component={SellStockMatchedBuyers}
                exact
              />
              <Route
                path="/Commodity_SellStock_ContractSummary"
                component={SellStockContractSummary}
                exact
              />

              {/* Added By abhishek */}
              <BDRouting
                restricted={false}
                path="/internal/retail-listing"
                component={InternalRetailListing}
              />
              <BDRouting
                restricted={true}
                path="/internal/login"
                component={InternalBdLogin}
              />
              <Route path="/spotpricing/:id" component={SpotPricing} />
              {/* disbursement start */}
              {/* <PrivateRoute
                path="/disbursement"
                component={DisbursementListing}
              />

              <PrivateRoute
                component={MobileLoanDetail}
                path="/disbursement-details/:id"
                // component={LoanDetail}
              />
              {/* disbursement End */}
              <PrivateRoute path="/invoice-view/:id" component={InvoiceView} />
              <PrivateRoute path="/trade-payments" component={TradePayments} />
              <PrivateRoute path="/Payment-Option" component={PaymentOption} />
              <PrivateRoute
                path="/stp/invoice-details/:id"
                component={InvoiceDetails}
              />
              <PrivateRoute
                path="/contract/invoice-details/:id"
                component={SupplierInvoiceDetails}
              />
              <PrivateRoute path="/stp" component={STP} />
              <PrivateRoute path="/contract" component={Contract} />
              <PrivateRoute path="/buyer-invoices" component={BuyerInvoices} />
              <PrivateRoute
                path="/seller-invoices"
                component={SellerInvoices}
              />
              <PrivateRoute
                path="/contractdetails"
                component={Contractdetails}
              />
              <PrivateRoute
                path="/ContractDetails/:id"
                component={ContractDetails}
              />
              {/* <PrivateRoute
                path="/create-new-auction"
                component={CreateMarketplaceAuction}
              /> */}
              {/* Trade Contracts */}
              <PrivateRoute
                path="/quote-detail/create-contract"
                component={TradeContracts}
              />
              <PrivateRoute
                path="/quote-detail/select-supply"
                component={SelectSupply}
              />
              <PrivateRoute
                path="/quote-detail/trade/:id"
                component={isMobile ? TradeContract : WrappedComponent}
                // component={SelectSupply}
              />
              <PrivateRoute
                path="/trade-contracts"
                component={TradeContractsListing}
              />
              {/* {isMobile ? ( */}
              {/* ) : (
                <Route path="/view-contract/:id" component={WrappedComponent} />
              )} */}
              <PrivateRoute
                path="/view-seller-contracts"
                component={SellerContractAccepted}
              />
              <PrivateRoute path="/flow4" component={Flow4Widget} />
              <PrivateRoute path="/flow5" component={Flow5Widget} />
              <PrivateRoute
                path="/view-contract-broker/:id"
                component={ViewContractForBroker}
              />
              {/* Block Chain Routes Start */}
              {/* DepositorListing */}
              {/* <Route path="/uni-blockchain" component={UnifiedController} /> */}
              <Route
                path="/view-token/history/:type"
                component={BlockchainHistory}
              />
              <Route
                path="/blockchain-details/:fromTabId/:id"
                component={NewBlockchainDetailsPage}
              />
              <PrivateRoute
                TYPE={USER_TYPE.BANKER}
                path="/blockchain/banker/:tab"
                component={NewBankerListingPage}
              />
              <PrivateRoute
                TYPE={USER_TYPE.BANKER}
                path="/blockchain/banker-login"
                component={BlockchainBankerLogin}
              />
              {/*      component={isMobile ? NewBankerListingPage : BankerListing} */}
              <PrivateRoute
                path="/blockchain/customize-filter"
                component={BlockchainCustomizeFilter}
              />
              <Route path="/blockchain/info" component={BlockchainInfoPage} />
              <Route
                TYPE={USER_TYPE.BANKER}
                path="/blockchain"
                component={PublicNewBankerListingPage}
              />
              {/* BankerPublicListing */}
              <Route
                path="/apply-loan/:id"
                component={NewApplyLoanFormView}
                // ApplyLoanFormView}
              />
              <Route
                path="/token-details/:id"
                component={NewTokenDetailPage}
                // TokenDetailPage
              />
              <Route
                path="/pledge-token-details/:id"
                component={PledgeTokenDetailPage}
                // TokenDetailPage
              />
              {/* <Route path="/token-details" component={TokenDetailPage} />
              <Route
                path="/blockchain-details/:fromTabId/:id"
                component={BlockchainDetailsPage}
              />
              <Route path="/blockchain-banker" component={BankerListing} />
              <Route
                path="/blockchain-banker-details/:id"
                component={OfferMadeDetailView}
              /> */}
              <Route path="/stp-ltp-trade" component={StpLtpTrade} />
              <Route
                path="/stack-detail-public"
                component={BlockChainPublicDetail}
              />
              {/* isMobile
                    ? 
                    : PublicTokenDetailPageBanker */}
              <Route
                exact
                path="/stack-detail"
                component={MarketplaceStackDetail}
              />

              <Route path="/sz*" component={ShortUrl} />

              <Route
                path="/banker/view-token-details"
                component={BankerViewDetailsPage}
              />
              {/* isMobile ? BankerViewDetailsPage : TokenDetailPageBanker */}
              <Route
                path="/banker/loan-request-details"
                component={BankerViewDetailsPage}
              />
              {/*  isMobile ?  : TokenDetailPageBanker */}
              <Route
                path="/banker/offers-details"
                component={BankerViewDetailsPage}
              />
              {/*  isMobile ? BankerViewDetailsPage : TokenDetailPageBanker */}
              <Route
                path="/add-customize-loan-offer"
                component={CustomizeLoanOfferForm}
              />
              <Route
                path="/edit-customize-loan-offer"
                component={CustomizeLoanOfferForm}
              />
              <Route
                path="/blockchain-file-setting"
                component={CustomizeMultipleCommodityAndCity}
              />
              <Route
                path="/make-loan-offer/:id"
                component={NewMakeLoanOfferForm}
              />
              <Route
                path="/blockchain-detail/:type"
                component={BlockchainDetailInfoPage}
                exact
              />
              {/* Block Chain Routes End */}

              <Route
                path="/TwoButtonDesktopModal"
                component={TwoButtonDesktopModal}
                exact
              />
              <Route
                path="/ltp-supplier-invoice-listing"
                component={LTPSupplierInvoiceListing}
                exact
              />
              <Route
                path="/ltp-supplier-invoice-summary"
                component={LTPSupplierInvoiceSummary}
                exact
              />

              <Route
                path="/trade/stp/offer-details"
                component={STPOfferDetails}
                exact
              />
              <Route path="/upload-document-form" component={UploadDocument} />
              <LoginMandatoryRoute
                path="/trade/stp-ltp/lead-renewal"
                component={STPAutoLeadRenewal}
                exact
              />
              {/* Block Chain Routes End */}
              {/* 404 */}
              <PrivateRoute
                path="/ltpstpdashboard"
                component={LTPSTPDashboard}
              />
              <PrivateRoute
                path="/user-bank-details"
                component={BankDetailsVerification}
              />
              <PrivateRoute path="/upi-payment" component={UPIPayment} />
              <PrivateRoute
                path="/quote-detail/create-contract"
                component={TradeContracts}
              />
              <PrivateRoute
                path="/quote-detail/select-supply"
                component={SelectSupply}
              />
              <PrivateRoute
                path="/trade-contracts"
                component={TradeContractsListing}
              />
              {/* <PrivateRoute
                path="/view-contract/:id"
                component={ViewContract}
              /> */}
              <PrivateRoute
                path="/view-seller-contracts"
                component={SellerContractAccepted}
              />
              <PrivateRoute path="/flow4" component={Flow4Widget} />
              <PrivateRoute path="/flow5" component={Flow5Widget} />
              {/* 404 */}
              <PrivateRoute
                path="/ltpstpdashboard"
                component={LTPSTPDashboard}
              />
              <PrivateRoute path="/order/payment" component={UPIPayment} />
              <PrivateRoute
                path="/sellerinvoicesupload"
                component={SellerInvoiceForm}
              />
              {/* Start of Price Capture */}
              <PrivateRoute
                path="/pricecapture"
                component={PitchCustomerSelectCommodity}
              />
              <PrivateRoute
                path="/price-capture-listing"
                component={PitchCustomerSelectSeller}
              />
              <PrivateRoute
                path="/create-campaign/:id"
                component={CreateCampaign}
              />
              <PrivateRoute
                path="/create-campaign"
                component={CreateCampaign}
              />
              <PrivateRoute
                path="/price-capture-detail/:id"
                component={PitchCustomerReadyToSell}
              />
              <PrivateRoute
                path="/view-recent-campaign"
                component={PitchCustomerViewRecentCampaign}
              />
              <Route
                path="/stp-seller-invoice-sumary"
                component={StpVirtualSellerSummary}
              />
              <Route
                path="/stp-buyer-invoice-sumary"
                component={StpVirtualBuyerSummary}
              />
              <Route
                exact
                path="/pay-money-margin"
                component={LTPBuyerPayMoneyMargin}
              />
              <Route
                exact
                path="/ltp-buyer-invoice"
                component={LTPBuyerInvoice}
              />
              <Route
                exact
                path="/ltp-buyer-invoice-summary"
                component={LtpBuyerInvoiceSummary}
              />
              <Route
                exact
                path="/ltp-buyer-invoice-listing/:tab"
                component={LtpBuyerInvoiceListing}
              />

              <Route path="/stpltp/release-stock" component={LTPReleaseStock} />
              <Route path="/pending-invoices" component={PendingInvoices} />
              <Route path="/service-requests" component={ServiceRequests} />
              {/* <Route path="/stpltp/pay" component={PaySTPLTPAmount} /> */}
              <LoginMandatoryRoute
                path="/credit-score"
                component={FetchCreditScore}
              />
              <LoginMandatoryRoute
                path="/credit-score-reports"
                component={CibilReports}
              />

              {/* <Route path="/stpltp/pay" component={PaySTPLTPAmount} /> */}
              {/* <Route
                path="/invoice-file-upload-form/:id"
                render={() => <Redirect to="/404" />}
              /> */}
              <Route path="/test-select" component={TestSelect} />
              <Redirect to="/404" />
            </Switch>
          </Suspense>
        </div>
      </Router>
    </Provider>
  );
}

export default App;

const Testing = (props) => {
  useEffect(() => {
    if (props.match.params.id) {
      props.history.push("/buyer-requirements/" + props.match.params.id);
    }
  }, [props.match.params.id]);
  return false;
};

const ScrollHandling = (props) => {
  const location = useLocation();
  useEffect(() => {
    window.history.scrollRestoration = "manual";
    window.scrollTo(0, 0);
    // setTimeout(() => {
    //   window.history.scrollRestoration = "auto";
    // }, 0);
  }, [location]);
  return false;
};
