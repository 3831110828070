import React, { useEffect, useState } from "react";
import { isMobile } from "../helperFunc";
import "../commonStyle.css";
import s from "./styles.module.css";
import LangPreferences from "./LangPreferences/LangPreferences";
import AccountTableCard from "./AccountTableCard/AccountTableCard";
import AccountTable from "./AccountTable";
import NotificationPreferences from "./NotificationPreferences";
import SideNavSelect from "../StpLtpTrade/LTPTrade/CommonComponent/SideNavSelect/SideNavSelect";
import {
  bankLogoIcon,
  bankhollowIcon,
  detailIcon,
  langIcon2,
  loginIcon,
  notifIcon,
  settingIcon,
  userIcon,
  walletIcon,
  tradeDetailsIcon,
} from "../icon_assets";
import AccountSettings from "./AccountSettings";
import { useSideScrollSticky } from "../CustomHooks/useSideScrollSticky";
import CommodityLocationPreferences from "./CommodityLocationPreferences";
import BankDetails from "./BankDetails/BankDetails";
import TradeDetail from "./TradeDetails/TradeDetails";
import ManageUserAccountSetting from "./ManageUserAccountSetting/ManageUserAccountSetting";

const AccountMenu = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const sideScrollFunc = useSideScrollSticky(undefined, undefined, 70, 1080);
  function handleClick(index) {
    setActiveIndex(index);
  }
  const sideNavitems = [
    {
      text: "General Details",
      imgSrc: detailIcon,
      onClick: () => {
        handleClick(0);
        window.scrollTo(0, 0);
      },
    },
    {
      text: "Manage Users",
      imgSrc: userIcon,
      onClick: () => {
        handleClick(1);
        window.scrollTo(0, 0);
      },
    },
    {
      text: "Commodity & Location Preference",
      imgSrc: settingIcon,
      onClick: () => {
        handleClick(2);
        window.scrollTo(0, 0);
      },
    },
    {
      text: "Language Preference",
      imgSrc: langIcon2,
      onClick: () => {
        handleClick(3);
        window.scrollTo(0, 0);
      },
    },
    {
      text: "Manage Notifications",
      imgSrc: notifIcon,
      onClick: () => {
        handleClick(4);
        window.scrollTo(0, 0);
      },
    },
    {
      text: "Bank Details",
      imgSrc: bankhollowIcon,
      onClick: () => {
        handleClick(5);
        window.scrollTo(0, 0);
      },
    },
    // {
    //   text: "Trade Details",
    //   imgSrc: tradeDetailsIcon,
    //   onClick: () => {
    //     handleClick(6);
    //     window.scrollTo(0, 0);
    //   },
    // },
    // {
    //   text: "Location Preference",
    //   imgSrc: loginIcon,
    //   onClick: () => {
    //     handleClick(6);
    //     window.scrollTo(0, 0);
    //   },
    // },
  ];

  useEffect(() => {
    if (sideScrollFunc) {
      setTimeout(sideScrollFunc(), 1000);
    }
  }, []);

  return (
    <div
      className={`d-flex  ${
        !isMobile ? `padding-left-right100 ${s["desk-min-content-height"]}` : ""
      } margin-top24 margin-bottom24 gap20 stdFontStyle`}
    >
      <>
        <div>
          <div className={!isMobile && `position-sticky sticky-sidebar`}>
            <div className={!isMobile && "sidebar"}>
              <SideNavSelect
                sideNavitems={sideNavitems}
                scrollOnActive={activeIndex}
                imageClassName={s["side-nav-image"]}
                titleClassName={`${s["side-nav-title"]} font-size16 line-height19 font-weight400 `}
                cardItemStyle="margin-bottom16 padding12"
                cardClassName={`padding-top16 padding-left16 padding-right16 ${s["colWidth"]}`}
              />
            </div>
          </div>
        </div>
        <div className={`${s["col2Width"]}`}>
          {activeIndex === 0 && <AccountSettings hideHeaderFooter={true} />}
          {activeIndex === 1 && <AccountTable />}
          {activeIndex === 2 && (
            <CommodityLocationPreferences hideHeaderFooter={false} />
          )}
          {activeIndex === 3 && <LangPreferences />}
          {activeIndex === 4 && <NotificationPreferences />}
          {activeIndex === 5 && <BankDetails />}
          {activeIndex === 6 && <TradeDetail />}
        </div>
      </>
    </div>
  );
};

export default AccountMenu;
